import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Button, //Imported Button @riniyad
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { Order, getOrderStatusLabel, getStatusChipColor } from "../../../../app/models/order.ts";
//changes done by riniyad : Change request : Tony
//Funtionality Added : User Can delete unapproved Orders
//changes done in the table : marked by comment "@riniyad"
const MyOrderList: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadMyOrders,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    deleteOrder, // @Riniyad Added this line to import the delete function from store
  } = orderStore;
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [OrderToDelete, setProductToDelete] = React.useState<number | null>(
    null
  );
  const [loading, setLoading] = React.useState({
    delete: false,
    toggle: false,
  });

  const [outOfStockDialog, setOutOfStockDialog] = useState({
    open: false,
    items: [] as any[],
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleDeleteOrder = async (orderId: number) => {
    try {
      setDeleteDialogOpen(true); // Open the dialog
      setProductToDelete(orderId); // Set the order to delete
    } catch (error) {
      console.error("Error deleting order:", error);
      setErrorMessage("An error occurred while deleting the order");
    }
  };

  const handleConfirmDelete = async () => {
    if (!OrderToDelete) return;
    setLoading((prev) => ({ ...prev, delete: true }));
    await orderStore.deleteOrder(OrderToDelete); // Call delete function from the store
    await navigateToHome(1); // Refresh the order list
    setLoading((prev) => ({ ...prev, delete: false }));
    setDeleteDialogOpen(false); // Close the dialog
  };

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadMyOrders();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const handleAddItemstoOrder = (order: Order) => {
    const serializedOrder = JSON.parse(JSON.stringify(order));
    navigate(
      `/user/addorderitem?orderId=${order.id}&clientId=${order.client.id} `,
      { state: { order: serializedOrder } }
    );
  };

  useEffect(() => {
    loadMyOrders();
  }, [loadMyOrders]);

  if (loadingInitial) return <CircularProgress />;

  return (
    <Box sx={{ p: 2 }}>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: (theme) => theme.palette.primary.main,
                }}
              >
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Order Number
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Order Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Client
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Sub Total
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Items
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Status
                  </Typography>
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders &&
                orders.map((order) => (
                  <TableRow
                    key={order.id}
                    sx={{
                      "&:hover": {
                        bgcolor: "action.hover",
                        transition: "background-color 0.2s ease",
                      },
                      borderBottom: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "monospace", fontWeight: "medium" }}
                      >
                        {order.orderNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {formatDate(order.orderDate)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        {order.client.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="primary.main"
                        sx={{ fontWeight: "medium" }}
                      >
                        ${order.subTotal}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {order.itemsCount}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={getOrderStatusLabel(order.status)}
                        color={getStatusChipColor(order.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        component={Link}
                        to={`${order.id}`}
                        size="small"
                        sx={{
                          color: "info.main",
                          "&:hover": {
                            bgcolor: "info.lighter",
                          },
                        }}
                      >
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    {(order.status === 0 || order.status === 10) && (
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          sx={{
                            minWidth: "100px",
                            "&:hover": {
                              bgcolor: "error.dark",
                            },
                          }}
                          onClick={() => handleDeleteOrder(order.id!)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    )}
                    {(order.status === 0 || order.status === 10) && (
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{
                            minWidth: "100px",
                            "&:hover": {
                              bgcolor: "primary.dark",
                            },
                          }}
                          onClick={() => handleAddItemstoOrder(order)}
                        >
                          Add Items
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Order?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
    </Box>
  );
};

export default observer(MyOrderList);
