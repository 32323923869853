export interface InventoryStats {
    totalProducts: number;
    normalStock: number;
    lowStock: LowStockProductDto[];
    criticalStock: LowStockProductDto[];
    nearExpiry: ExpiringProductDto[];
    stockTrends: { [key: string]: number };
}

export interface LowStockProductDto {
    id: number;
    name: string;
    code: string;
    quantity: number;
    minimumStock: number;
    lastRestocked: string;
}

export interface ExpiringProductDto {
    id: number;
    name: string;
    expiryDate: string;
    quantity: number;
    batchNumber: string;
}

export interface InventoryThresholds {
    caseLowStockThreshold: number;
    caseCriticalThreshold: number;
    unitLowStockThreshold: number;
    unitCriticalThreshold: number;
}

export type ExpiryStatusType = 'normal' | 'warning' | 'critical' | 'expired';

export const EXPIRY_STATUS: Record<string, ExpiryStatusType> = {
    NORMAL: 'normal',
    WARNING: 'warning',
    CRITICAL: 'critical',
    EXPIRED: 'expired'
} as const;

export const EXPIRY_COLORS: Record<ExpiryStatusType, {
    light: string;
    border: string;
    text: string;
    badge: string;
}> = {
    normal: {
        light: 'rgba(236, 253, 245, 0.95)',
        border: '#34d399',
        text: '#064e3b',
        badge: '#059669'
    },
    warning: {
        light: 'rgba(255, 251, 235, 0.95)',
        border: '#fbbf24',
        text: '#78350f',
        badge: '#d97706'
    },
    critical: {
        light: 'rgba(255, 247, 237, 0.95)',
        border: '#fb923c',
        text: '#7c2d12',
        badge: '#ea580c'
    },
    expired: {
        light: 'rgba(254, 242, 242, 0.95)',
        border: '#f87171',
        text: '#991b1b',
        badge: '#dc2626'
    },
}; 