export enum ReturnStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Processed = "Processed"
}

export interface Return {
  id?: number;
  orderId: number;
  orderNumber?: string;
  returnDate?: Date;
  status?: ReturnStatus;
  returnType: string;
  reason: string;
  notes?: string;
  clientName?: string;
  createdAt?: Date;
  items: ReturnItem[];
}

export interface ReturnItem {
  id?: number;
  orderItemId: number;
  productName?: string;
  productCode?: string;
  quantity: number;
  lots: ReturnLot[];
}

export interface ReturnLot {
  lotId: number;
  lotNumber: string;
  quantity: number;
} 

export interface ReturnDto {
  id: number;
  orderId: number;
  returnDate: string;
  status: number;
  returnType: string;
  reason: string;
  notes: string;
  createdAt: string;
  processedAt: string | null;
  items: ReturnItemDto[];
}

export interface ReturnItemDto {
  id: number;
  returnId: number;
  orderItemId: number;
  quantity: number;
  isCaseQuantity: boolean;
  lots: ReturnLotDto[];
}

export interface ReturnLotDto {
  id: number;
  returnItemId: number;
  productId: number;
  lotNumber: string;
  quantity: number;
}

export enum ReturnType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  DAMAGED = 'DAMAGED',
  WRONG_ITEM = 'WRONG_ITEM',
  QUALITY_ISSUE = 'QUALITY_ISSUE',
  EXPIRED = 'EXPIRED',
  CUSTOMER_DISSATISFACTION = 'CUSTOMER_DISSATISFACTION',
  SHIPPING_ERROR = 'SHIPPING_ERROR',
  OTHER = 'OTHER'
}