import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
} from '@mui/material';
import { useStore } from '../../../app/stores/store.ts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import agent from '../../../app/api/agent.ts';
import LoadingComponent from '../../../app/layout/LoadingComponent.tsx';
import { EXPIRY_STATUS, EXPIRY_COLORS } from '../../../app/models/inventory.ts';

interface UserDashboardStats {
  recentOrders: {
    id: string;
    date: string;
    status: string;
    total: number;
    items: number;
  }[];
  orderStats: {
    totalOrders: number;
    pendingDelivery: number;
    totalSpent: number;
  };
  frequentlyOrderedProducts: {
    id: string;
    name: string;
    lastOrderedDate: string;
    price: number;
  }[];
  expiringProducts: Array<{
    id: string;
    name: string;
    expiryDate: string;
    quantity: number;
    batchNumber: string;
  }>;
}

const HomePage = () => {
  const { userStore, cartStore } = useStore();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<UserDashboardStats | null>(null);

  useEffect(() => {
    const loadDashboardStats = async () => {
      try {
        const data = await agent.Dashboard.getDashboardStats();
        setStats(data);
      } catch (error) {
        console.error('Error loading dashboard stats:', error);
      } finally {
        setLoading(false);
      }
    };

    loadDashboardStats();
  }, []);

  if (loading || !stats) return <LoadingComponent message="Loading dashboard..." />;

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          backgroundColor: "#fff",
          p: 2.5,
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          Welcome back, {userStore.user?.displayName}!
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Card
            elevation={3}
            sx={{
              background: "linear-gradient(135deg, #2196F3 0%, #21CBF3 100%)",
              color: "white",
              transition: "all 0.3s ease",
              height: "120px",
              "&:hover": {
                transform: "translateY(-3px)",
                boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
              },
            }}
          >
            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ShoppingCartIcon sx={{ color: "rgba(255,255,255,0.8)" }} />
                <Typography variant="h6" sx={{ color: "rgba(255,255,255,0.8)", fontSize: "1rem", fontWeight: 500 }}>
                  Cart Items
                </Typography>
              </Box>
              <Typography variant="h4" sx={{ fontSize: "2rem", fontWeight: 600 }}>
                {cartStore.totalItems}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            elevation={3}
            sx={{
              background: "linear-gradient(135deg, #4CAF50 0%, #8BC34A 100%)",
              color: "white",
              transition: "all 0.3s ease",
              height: "120px",
              "&:hover": {
                transform: "translateY(-3px)",
                boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
              },
            }}
          >
            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ReceiptIcon sx={{ color: "rgba(255,255,255,0.8)" }} />
                <Typography variant="h6" sx={{ color: "rgba(255,255,255,0.8)", fontSize: "1rem", fontWeight: 500 }}>
                  Total Orders
                </Typography>
              </Box>
              <Typography variant="h4" sx={{ fontSize: "2rem", fontWeight: 600 }}>
                {stats.orderStats.totalOrders}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            elevation={3}
            sx={{
              background: "linear-gradient(135deg, #FF9800 0%, #FFC107 100%)",
              color: "white",
              transition: "all 0.3s ease",
              height: "120px",
              "&:hover": {
                transform: "translateY(-3px)",
                boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
              },
            }}
          >
            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocalShippingIcon sx={{ color: "rgba(255,255,255,0.8)" }} />
                <Typography variant="h6" sx={{ color: "rgba(255,255,255,0.8)", fontSize: "1rem", fontWeight: 500 }}>
                  Pending Delivery
                </Typography>
              </Box>
              <Typography variant="h4" sx={{ fontSize: "2rem", fontWeight: 600 }}>
                {stats.orderStats.pendingDelivery}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Paper
            sx={{
              p: 3,
              borderRadius: 2,
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              height: 600,
            }}
            elevation={3}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                borderBottom: "2px solid #2196F3",
                pb: 1,
                mb: 2,
              }}
            >
              Recent Orders
            </Typography>
            <List sx={{ maxHeight: "calc(100% - 50px)", overflowY: "auto" }}>
              {stats.recentOrders.map((order) => (
                <React.Fragment key={order.id}>
                  <ListItem>
                    <ListItemText
                      primary={`Order #${order.id}`}
                      secondary={new Date(order.date).toLocaleDateString()}
                    />
                    <Box>
                      <Chip 
                        label={order.status}
                        size="small"
                        color={order.status === 'Delivered' ? 'success' : 'warning'}
                        sx={{ mr: 2 }}
                      />
                      <Typography variant="subtitle2" color="primary">
                        ${order.total.toFixed(2)}
                      </Typography>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper
            sx={{
              p: 3,
              borderRadius: 2,
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              height: 600,
            }}
            elevation={3}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                borderBottom: "2px solid #2196F3",
                pb: 1,
                mb: 2,
              }}
            >
              Products Expiring Soon
            </Typography>
            <Box
              sx={{
                maxHeight: "calc(100% - 50px)",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "#666",
                  },
                },
              }}
            >
              {(stats?.expiringProducts?.length === 0) ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 100,
                    color: "text.secondary",
                  }}
                >
                  <Typography>No expiring products to display</Typography>
                </Box>
              ) : (
                stats.expiringProducts
                  .sort((a, b) => new Date(a.expiryDate).getTime() - new Date(b.expiryDate).getTime())
                  .map((product) => {
                    const daysUntilExpiry = Math.ceil(
                      (new Date(product.expiryDate).getTime() - new Date().getTime()) /
                      (1000 * 60 * 60 * 24)
                    );

                    let status = EXPIRY_STATUS.NORMAL;
                    if (daysUntilExpiry <= -1) status = EXPIRY_STATUS.EXPIRED;
                    else if (daysUntilExpiry <= 30) status = EXPIRY_STATUS.CRITICAL;
                    else if (daysUntilExpiry <= 60) status = EXPIRY_STATUS.WARNING;

                    const colors = EXPIRY_COLORS[status];

                    return (
                      <Paper
                        key={`expiry-${product.id}-${product.batchNumber}`}
                        sx={{
                          mb: 1.5,
                          p: 2,
                          border: "1px solid",
                          borderColor: colors.border,
                          backgroundColor: colors.light,
                          borderRadius: "8px",
                          transition: "all 0.2s ease-in-out",
                          opacity: 0.98,
                          transform: "translateX(0)",
                          "&:hover": {
                            transform: "translateX(5px)",
                            boxShadow: `0 4px 12px ${colors.border}33`,
                            opacity: 1,
                          },
                          background: `linear-gradient(145deg, ${colors.light} 0%, ${colors.light}ee 100%)`,
                          position: "relative",
                          "&::before": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: `linear-gradient(145deg, transparent, ${colors.border}15)`,
                            borderRadius: "inherit",
                            pointerEvents: "none",
                          },
                        }}
                      >
                        <Grid container spacing={1.5}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 600,
                                color: colors.text,
                                letterSpacing: '0.015em',
                              }}
                            >
                              {product.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: colors.text,
                                opacity: 0.95,
                                fontWeight: 500,
                              }}
                            >
                              Lot: {product.batchNumber}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: 0.5,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  color: colors.text,
                                  opacity: 0.9,
                                  fontWeight: 500,
                                }}
                              >
                                {status === EXPIRY_STATUS.EXPIRED ? "Expired on: " : "Expires: "}
                                {new Date(product.expiryDate).toLocaleDateString()}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  backgroundColor: colors.badge,
                                  color: "#fff",
                                  px: 1.5,
                                  py: 0.75,
                                  borderRadius: "6px",
                                  fontWeight: 600,
                                  letterSpacing: '0.02em',
                                  fontSize: '0.75rem',
                                }}
                              >
                                {daysUntilExpiry <= -1
                                  ? `Expired ${Math.abs(daysUntilExpiry)} days ago`
                                  : `${daysUntilExpiry} days left`}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              borderRadius: 2,
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              minHeight: "300px",
            }}
            elevation={3}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                borderBottom: "2px solid #2196F3",
                pb: 1,
                mb: 2,
              }}
            >
              Frequently Ordered Products
            </Typography>
            <List sx={{ 
              display: 'grid', 
              gridTemplateColumns: {
                xs: '1fr',
                md: '1fr 1fr',
                lg: '1fr 1fr 1fr'
              },
              gap: 2 
            }}>
              {stats.frequentlyOrderedProducts.map((product) => (
                <ListItem key={product.id}>
                  <ListItemText
                    primary={product.name}
                    secondary={`Last ordered: ${new Date(product.lastOrderedDate).toLocaleDateString()}`}
                  />
                  <Typography variant="subtitle2" color="primary">
                    ${product.price.toFixed(2)}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(HomePage);
