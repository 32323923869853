import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Chip,
  Divider,
  Modal,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Checkbox,
  TextField,
  Breadcrumbs,
  Snackbar,
  Card,
  CardContent,
  styled,
  Link as RouterLink
} from '@mui/material';
import { useStore } from '../../../../app/stores/store.ts';
import InfoIcon from '@mui/icons-material/Info';
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import agent from '../../../../app/api/agent.ts';
import { OrderStatus } from "../../../../app/models/order.ts";
import { OrderDetail } from '../../../../app/models/orderDetail.ts';
import EditIcon from '@mui/icons-material/Edit';
import { LotAssignment } from '../../../../app/models/lotAssignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InventoryIcon from '@mui/icons-material/Inventory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { alpha } from '@mui/material/styles';

const PrepareShipmentScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { orderStore } = useStore();
  const { loadOrderItems, loadingInitial, loadOrderById } = orderStore;
  const navigate = useNavigate();
  
  // Consolidated state
  const [state, setState] = useState({
    orderNumber: null as string | null,
    orderDetails: null as OrderDetail[] | null,
    selectedLot: null as any
  });

  // Add new state for processing status
  const [processing, setProcessing] = useState(false);
  const [processError, setProcessError] = useState<string | null>(null);

  // Add new state for lot selection dialog
  const [lotSelectionDialog, setLotSelectionDialog] = useState({
    open: false,
    orderItemId: null as number | null,
    availableLots: [] as any[],
    selectedLotIds: [] as number[],
    currentLots: [] as any[],
    quantities: {} as Record<number, { cases: number, units: number }>,
    expectedQuantity: { cases: 0, units: 0 },
    validationError: null as string | null
  });

  // Add new state for success notification
  const [successNotification, setSuccessNotification] = useState({
    open: false,
    message: ''
  });

  // Combined useEffect for initial data loading
  useEffect(() => {
    const fetchOrderData = async () => {
      if (!id) return;

      try {
        // Load order details and items in parallel
        const [order, orderItemsWithLots] = await Promise.all([
          loadOrderById(Number(id)),
          agent.Orders.getOrderItemsWithLots(Number(id))
        ]);

        setState(prev => ({
          ...prev,
          orderNumber: order?.orderNumber || null,
          orderDetails: orderItemsWithLots
        }));
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
    loadOrderItems(Number(id));
  }, [id, loadOrderItems, loadOrderById]);

  // Simplified handlers
  const handleLotSelect = (lot: any) => setState(prev => ({ ...prev, selectedLot: lot }));
  const handleModalClose = () => setState(prev => ({ ...prev, selectedLot: null }));
  const {
    updateOrderStatus,
  } = orderStore;
  // Add new handler for processing order
  const handleProcessOrder = async () => {
    if (!id) return;
    
    setProcessing(true);
    setProcessError(null);
    
    try {
      await updateOrderStatus(Number(id), OrderStatus.WaitingToShip);
      
      // Show success notification
      setSuccessNotification({
        open: true,
        message: `Order #${state.orderNumber} has been processed and moved to shipping queue`
      });

      // Wait a brief moment before navigating to allow user to see the notification
      setTimeout(() => {
        navigate('/admin/warehouse/order-management/');
      }, 2000);
      
    } catch (error) {
      console.error("Error processing order:", error);
      setProcessError("Failed to process order. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  // Add function to fetch available lots
  const fetchAvailableLots = async (productId: number) => {
    try {
      const lots = await agent.ProductLots.getByProductNumber(productId);
      return lots;
    } catch (error) {
      console.error("Failed to fetch available lots:", error);
      return [];
    }
  };

  // Add function to handle lot change request
  const handleChangeLot = async (orderItem: any, currentLots: any[]) => {
    const availableLots = await fetchAvailableLots(orderItem.productId);
    
    const initialQuantities = currentLots.reduce((acc, lot) => ({
      ...acc,
      [lot.lotId]: {
        cases: lot.caseQuantity || 0,
        units: lot.unitQuantity || 0
      }
    }), {});

    setLotSelectionDialog({
      open: true,
      orderItemId: orderItem.orderItemId,
      availableLots,
      selectedLotIds: currentLots.map(lot => lot.lotId),
      currentLots,
      quantities: initialQuantities,
      expectedQuantity: {
        cases: orderItem.byCase ? orderItem.quantity : 0,
        units: !orderItem.byCase ? orderItem.quantity : 0
      },
      validationError: null
    });
  };

  // Add function to handle lot selection confirmation
  const handleLotSelectionConfirm = async () => {
    if (!lotSelectionDialog.selectedLotIds.length) return;

    // Check for zero quantities
    const hasZeroQuantity = lotSelectionDialog.selectedLotIds.some(lotId => {
      const lotQuantity = lotSelectionDialog.quantities[lotId];
      return (!lotQuantity || (lotQuantity.cases === 0 && lotQuantity.units === 0));
    });

    if (hasZeroQuantity) {
      setLotSelectionDialog(prev => ({
        ...prev,
        validationError: 'Selected lots must have non-zero quantities'
      }));
      return;
    }

    const totalSelected = lotSelectionDialog.selectedLotIds.reduce(
      (acc, lotId) => ({
        cases: acc.cases + (lotSelectionDialog.quantities[lotId]?.cases || 0),
        units: acc.units + (lotSelectionDialog.quantities[lotId]?.units || 0)
      }),
      { cases: 0, units: 0 }
    );

    if (totalSelected.cases !== lotSelectionDialog.expectedQuantity.cases ||
        totalSelected.units !== lotSelectionDialog.expectedQuantity.units) {
      setLotSelectionDialog(prev => ({
        ...prev,
        validationError: 'Selected quantities must match the order quantity'
      }));
      return;
    }

    try {
      const lotAssignments: LotAssignment[] = lotSelectionDialog.selectedLotIds.map(lotId => ({
        lotId,
        caseQuantity: lotSelectionDialog.quantities[lotId]?.cases || 0,
        unitQuantity: lotSelectionDialog.quantities[lotId]?.units || 0
      }));

      await agent.Orders.updateOrderItemLots(
        lotSelectionDialog.orderItemId!,
        lotAssignments
      );

      // Update local state - now with packed status set to false for new assignments
      setState(prev => ({
        ...prev,
        orderDetails: {
          ...prev.orderDetails!,
          items: prev.orderDetails!.items.map(item => {
            if (item.orderItemId === lotSelectionDialog.orderItemId) {
              return {
                ...item,
                lots: lotSelectionDialog.availableLots
                  .filter(lot => lotSelectionDialog.selectedLotIds.includes(lot.id))
                  .map(lot => ({
                    lotId: lot.id,
                    lotNumber: lot.lotNumber,
                    caseQuantity: lotSelectionDialog.quantities[lot.id]?.cases || 0,
                    unitQuantity: lotSelectionDialog.quantities[lot.id]?.units || 0,
                    itemsInCase: lot.itemsInCase,
                    warehouseLocation: lot.warehouseLocation,
                    packed: false // Always set to false when lots are changed
                  }))
              };
            }
            return item;
          })
        }
      }));

      setLotSelectionDialog(prev => ({ ...prev, open: false }));
    } catch (error) {
      console.error("Failed to update lot assignments:", error);
      setProcessError("Failed to update lot assignments. Please try again.");
    }
  };

  // Add a helper function to check quantities
  const validateQuantities = (quantities: Record<number, { cases: number, units: number }>, selectedLotIds: number[], expectedQuantity: { cases: number, units: number }) => {
    // Check if any selected lot has zero quantity
    const hasZeroQuantity = selectedLotIds.some(lotId => {
      const lotQuantity = quantities[lotId];
      return (!lotQuantity || (lotQuantity.cases === 0 && lotQuantity.units === 0));
    });

    if (hasZeroQuantity) {
      return false;
    }

    const totalSelected = selectedLotIds.reduce(
      (acc, lotId) => ({
        cases: acc.cases + (quantities[lotId]?.cases || 0),
        units: acc.units + (quantities[lotId]?.units || 0)
      }),
      { cases: 0, units: 0 }
    );

    return totalSelected.cases === expectedQuantity.cases && 
           totalSelected.units === expectedQuantity.units;
  };

  const handlePackedStatusChange = async (orderItemId: number, lotId: number, packed: boolean) => {
    try {
      await agent.Orders.updateLotPackedStatus(orderItemId, lotId, packed);
    } catch (error) {
      console.error("Failed to update packed status:", error);
      // Revert the UI state since the API call failed
      setState(prev => ({
        ...prev,
        orderDetails: {
          ...prev.orderDetails!,
          items: prev.orderDetails!.items.map(item => 
            item.orderItemId === orderItemId 
              ? {
                  ...item,
                  lots: item.lots.map(lot =>
                    lot.lotId === lotId 
                      ? { ...lot, packed: !packed }
                      : lot
                  )
                }
              : item
          )
        }
      }));
    }
  };

  const areAllLotsPacked = () => {
    if (!state.orderDetails?.items) return false;
    
    return state.orderDetails.items.every(item => 
      item.lots.length > 0 && item.lots.every(lot => lot.packed)
    );
  };

  // Add near the end of the component, before the return statement
  const handleCloseNotification = () => {
    setSuccessNotification(prev => ({ ...prev, open: false }));
  };

  // Add these styled components
  const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
    marginBottom: theme.spacing(3)
  }));

  const ShipmentHeader = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(4),
    borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
    marginBottom: theme.spacing(3)
  }));

  if (loadingInitial) return <CircularProgress />;
  if (!state.orderDetails?.items?.length) return <Typography>No items found for this order</Typography>;

  return (
    <Box sx={{ p: 3, maxWidth: 1400, margin: '0 auto' }}>
      {/* Update Header Section */}
      <ShipmentHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Prepare Shipment
            </Typography>
            <Breadcrumbs sx={{ color: 'inherit' }}>
              <Link 
                color="inherit" 
                component={RouterLink}
                to="/admin/warehouse/order-management"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <LocalShippingIcon sx={{ mr: 0.5 }} fontSize="small" />
                Order Management
              </Link>
              <Typography color="inherit">
                Order #{state.orderNumber}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/admin/warehouse/order-management')}
          >
            Back to Orders
          </Button>
        </Box>
      </ShipmentHeader>

      {state.orderDetails && (
        <>
          {/* Order Summary Card */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <ReceiptLongIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Order Details</Typography>
                <Chip 
                  label={state.orderDetails.status}
                  color={state.orderDetails.status === 'Approved' ? 'success' : 'default'}
                  sx={{ ml: 'auto' }}
                />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ 
                    p: 2, 
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box sx={{ 
                        p: 1.5, 
                        borderRadius: '50%', 
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1)
                      }}>
                        <LocalShippingIcon color="primary" />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Order Number
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {state.orderNumber}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Order Items Table */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <InventoryIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Order Items</Typography>
              </Box>

              <TableContainer component={Paper} sx={{ 
                borderRadius: 2,
                boxShadow: 'none',
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: 'grey.100' }}>
                      <TableCell width="25%">Product</TableCell>
                      <TableCell width="25%">Details</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell>Assigned Lots</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.orderDetails.items.map((item) => (
                      <TableRow key={item.orderItemId}>
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                            {/* Product Image */}
                            <Paper 
                              elevation={0} 
                              sx={{ 
                                width: 100, 
                                height: 100, 
                                overflow: 'hidden',
                                borderRadius: 1,
                                border: '1px solid',
                                borderColor: 'divider'
                              }}
                            >
                              <img
                                src={`${process.env.REACT_APP_API_ROOT}${item.productImageUrl}`}
                                alt={item.productName}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain'
                                }}  
                                
                              />
                            </Paper>
                            
                            {/* Product Info */}
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                {item.productName}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                                Code: {item.code}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{item.productDescription}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Category: {item.category}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {item.quantity} {item.byCase ? 'Cases' : 'Units'}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {/* Header */}
                            <Box sx={{ 
                              display: 'flex', 
                              justifyContent: 'space-between', 
                              alignItems: 'center'
                            }}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Assigned Lots ({item.lots.length})
                              </Typography>
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={() => handleChangeLot(item, item.lots)}
                                startIcon={<EditIcon />}
                                sx={{ minWidth: '120px' }}
                              >
                                Change Lots
                              </Button>
                            </Box>

                            {/* Lots List */}
                            {item.lots.length > 0 ? (
                              <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 1,
                                backgroundColor: 'grey.50',
                                borderRadius: 1,
                                p: 1
                              }}>
                                {item.lots.map((lot) => (
                                  <Paper
                                    key={`lot-box-${lot.lotId || `${lot.lotNumber}-${lot.warehouseLocation}`}`}
                                    elevation={0}
                                    sx={{
                                      p: 1.5,
                                      border: '1px solid',
                                      borderColor: 'divider',
                                      '&:hover': {
                                        backgroundColor: 'action.hover',
                                        cursor: 'pointer'
                                      }
                                    }}
                                    onClick={() => handleLotSelect(lot)}
                                  >
                                    <Grid container spacing={2} alignItems="center">
                                      {/* Lot Info */}
                                      <Grid item xs={12} sm={4}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          <InfoIcon sx={{ color: 'primary.main', fontSize: '1.2rem' }} />
                                          <Box>
                                            <Typography variant="subtitle2">
                                              Lot: {lot.lotNumber}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                              Location: {lot.warehouseLocation}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>

                                      {/* Quantity Info */}
                                      <Grid item xs={12} sm={4}>
                                        <Box sx={{ 
                                          display: 'flex', 
                                          gap: 2,
                                          justifyContent: { xs: 'flex-start', sm: 'center' }
                                        }}>
                                          {lot.caseQuantity > 0 && (
                                            <Chip
                                              label={`${lot.caseQuantity} Cases`}
                                              size="small"
                                              variant="outlined"
                                              sx={{ 
                                                borderColor: 'primary.main',
                                                color: 'primary.main',
                                                backgroundColor: 'primary.lighter'
                                              }}
                                            />
                                          )}
                                          {lot.unitQuantity > 0 && (
                                            <Chip
                                              label={`${lot.unitQuantity} Units`}
                                              size="small"
                                              variant="outlined"
                                              sx={{ 
                                                borderColor: 'secondary.main',
                                                color: 'secondary.main',
                                                backgroundColor: 'secondary.lighter'
                                              }}
                                            />
                                          )}
                                        </Box>
                                      </Grid>

                                      {/* Packed Status */}
                                      <Grid item xs={12} sm={4}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                          <Checkbox
                                            checked={lot.packed || false}
                                            onChange={(e) => {
                                              e.stopPropagation(); // Prevent lot selection modal from opening
                                              const newPackedStatus = e.target.checked;
                                              
                                              // Update UI immediately for better UX
                                              setState(prev => ({
                                                ...prev,
                                                orderDetails: {
                                                  ...prev.orderDetails!,
                                                  items: prev.orderDetails!.items.map(i => 
                                                    i.orderItemId === item.orderItemId 
                                                      ? {
                                                          ...i,
                                                          lots: i.lots.map(l =>
                                                            l.lotId === lot.lotId 
                                                              ? { ...l, packed: newPackedStatus }
                                                              : l
                                                          )
                                                        }
                                                      : i
                                                  )
                                                }
                                              }));
                                              
                                              // Update the database
                                              handlePackedStatusChange(item.orderItemId, lot.lotId, newPackedStatus);
                                            }}
                                            onClick={(e) => e.stopPropagation()} // Prevent lot selection modal from opening
                                          />
                                          <Typography variant="body2" color="text.secondary">
                                            Packed
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                ))}
                              </Box>
                            ) : (
                              <Box sx={{ 
                                p: 2, 
                                textAlign: 'center', 
                                backgroundColor: 'grey.50',
                                borderRadius: 1
                              }}>
                                <Typography variant="body2" color="text.secondary">
                                  No lots assigned
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>

          {/* Action Footer */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2, 
              mt: 3, 
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center' 
            }}>
              <Typography variant="body2" color="text.secondary">
                {!areAllLotsPacked() && 'All lots must be marked as packed before processing'}
                {processing && 'Processing order...'}
                {areAllLotsPacked() && !processing && 'Ready to process order?'}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => navigate('/admin/warehouse/order-management')}
                  disabled={processing}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleProcessOrder}
                  disabled={processing || !areAllLotsPacked()}
                  startIcon={processing ? <CircularProgress size={20} /> : <CheckCircleIcon />}
                >
                  {processing ? 'Processing...' : 'Process Order'}
                </Button>
              </Box>
            </Box>
          </Paper>
        </>
      )}

      <Modal
        open={Boolean(state.selectedLot)}
        onClose={handleModalClose}
        aria-labelledby="lot-details-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}>
          {state.selectedLot && (
            <>
              <Typography variant="h6" gutterBottom>
                Lot Details - {state.selectedLot.lotNumber}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Cases:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{state.selectedLot.caseQuantity}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Units:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{state.selectedLot.unitQuantity}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Items per Case:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{state.selectedLot.itemsInCase}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Location:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{state.selectedLot.warehouseLocation}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Expiry Date:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {state.selectedLot.expiryDate 
                      ? new Date(state.selectedLot.expiryDate).toLocaleDateString() 
                      : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleModalClose}>Close</Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      {/* Add error message display */}
      {processError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {processError}
        </Alert>
      )}

      <Dialog 
        open={lotSelectionDialog.open} 
        onClose={() => setLotSelectionDialog(prev => ({ ...prev, open: false }))}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Select Lots</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Expected Quantity: {lotSelectionDialog.expectedQuantity.cases > 0 
                ? `${lotSelectionDialog.expectedQuantity.cases} Cases` 
                : `${lotSelectionDialog.expectedQuantity.units} Units`}
            </Typography>
            
            {lotSelectionDialog.selectedLotIds.length > 0 && (
              <Typography variant="subtitle2" color={
                lotSelectionDialog.validationError ? "error" : "text.secondary"
              }>
                Total Selected: {
                  lotSelectionDialog.selectedLotIds.reduce(
                    (acc, lotId) => ({
                      cases: acc.cases + (lotSelectionDialog.quantities[lotId]?.cases || 0),
                      units: acc.units + (lotSelectionDialog.quantities[lotId]?.units || 0)
                    }),
                    { cases: 0, units: 0 }
                  ).cases > 0 
                    ? `${lotSelectionDialog.selectedLotIds.reduce(
                        (acc, lotId) => acc + (lotSelectionDialog.quantities[lotId]?.cases || 0),
                        0
                      )} Cases` 
                    : `${lotSelectionDialog.selectedLotIds.reduce(
                        (acc, lotId) => acc + (lotSelectionDialog.quantities[lotId]?.units || 0),
                        0
                      )} Units`
                }
              </Typography>
            )}
            
            {lotSelectionDialog.validationError && (
              <Alert severity="error" sx={{ mt: 1 }}>
                {lotSelectionDialog.validationError}
              </Alert>
            )}
          </Box>
          
          <List>
            {lotSelectionDialog.availableLots.map((lot) => (
              <ListItem 
                key={`dialog-lot-${lot.id}`}
                sx={{
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  mb: 1
                }}
              >
                <Checkbox
                  checked={lotSelectionDialog.selectedLotIds.includes(lot.id)}
                  onChange={(e) => {
                    setLotSelectionDialog(prev => ({
                      ...prev,
                      selectedLotIds: e.target.checked 
                        ? [...prev.selectedLotIds, lot.id]
                        : prev.selectedLotIds.filter(id => id !== lot.id),
                      quantities: e.target.checked 
                        ? { 
                            ...prev.quantities, 
                            [lot.id]: prev.quantities[lot.id] || { cases: 0, units: 0 } 
                          }
                        : prev.quantities
                    }));
                  }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    Lot: {lot.lotNumber} - Location: {lot.warehouseLocation}
                    {lot.expiryDate && (
                      <Typography 
                        component="span" 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{ ml: 2 }}
                      >
                        Expires: {new Date(lot.expiryDate).toLocaleDateString()}
                      </Typography>
                    )}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      Available: Cases: {lot.caseQuantity}, Units: {lot.unitQuantity}
                    </Typography>
                    {lotSelectionDialog.selectedLotIds.includes(lot.id) && (
                      <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <TextField
                              label="Cases"
                              type="number"
                              size="small"
                              value={lotSelectionDialog.quantities[lot.id]?.cases || 0}
                              onChange={(e) => {
                                const newValue = Math.max(0, parseInt(e.target.value) || 0);
                                const currentValue = lotSelectionDialog.quantities[lot.id]?.cases || 0;
                                
                                // Find the original lot to get its initial quantity
                                
                                // Calculate available quantity (original + currently selected)
                                const totalAvailable = lot.caseQuantity + currentValue;
                                
                                // Ensure newValue doesn't exceed available quantity
                                const finalValue = Math.min(newValue, totalAvailable);
                                
                                // Update the available lots
                                const updatedLots = lotSelectionDialog.availableLots.map(l => {
                                  if (l.id === lot.id) {
                                    return {
                                      ...l,
                                      caseQuantity: totalAvailable - finalValue
                                    };
                                  }
                                  return l;
                                });

                                const newQuantities = {
                                  ...lotSelectionDialog.quantities,
                                  [lot.id]: {
                                    ...lotSelectionDialog.quantities[lot.id],
                                    cases: finalValue
                                  }
                                };
                                
                                setLotSelectionDialog(prev => ({
                                  ...prev,
                                  quantities: newQuantities,
                                  availableLots: updatedLots,
                                  validationError: validateQuantities(newQuantities, prev.selectedLotIds, prev.expectedQuantity) 
                                    ? null 
                                    : 'Selected quantities must match the order quantity'
                                }));
                              }}
                              InputProps={{
                                inputProps: { 
                                  min: 0,
                                  max: lot.caseQuantity + (lotSelectionDialog.quantities[lot.id]?.cases || 0)
                                }
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Units"
                              type="number"
                              size="small"
                              value={lotSelectionDialog.quantities[lot.id]?.units || 0}
                              onChange={(e) => {
                                const newValue = Math.max(0, parseInt(e.target.value) || 0);
                                const currentValue = lotSelectionDialog.quantities[lot.id]?.units || 0;
                                
                                // Calculate available quantity (original + currently selected)
                                const totalAvailable = lot.unitQuantity + currentValue;
                                
                                // Ensure newValue doesn't exceed available quantity
                                const finalValue = Math.min(newValue, totalAvailable);
                                
                                // Update the available lots with correct unit quantities
                                const updatedLots = lotSelectionDialog.availableLots.map(l => {
                                  if (l.id === lot.id) {
                                    return {
                                      ...l,
                                      unitQuantity: totalAvailable - finalValue
                                    };
                                  }
                                  return l;
                                });

                                const newQuantities = {
                                  ...lotSelectionDialog.quantities,
                                  [lot.id]: {
                                    ...lotSelectionDialog.quantities[lot.id],
                                    units: finalValue,
                                    cases: lotSelectionDialog.quantities[lot.id]?.cases || 0 // Preserve case quantity
                                  }
                                };
                                
                                setLotSelectionDialog(prev => ({
                                  ...prev,
                                  quantities: newQuantities,
                                  availableLots: updatedLots,
                                  validationError: validateQuantities(newQuantities, prev.selectedLotIds, prev.expectedQuantity)
                                    ? null
                                    : 'Selected quantities must match the order quantity'
                                }));
                              }}
                              InputProps={{
                                inputProps: { 
                                  min: 0,
                                  max: lot.unitQuantity + (lotSelectionDialog.quantities[lot.id]?.units || 0)
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setLotSelectionDialog(prev => ({ 
              ...prev, 
              open: false,
              validationError: null 
            }))}
            color="inherit"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleLotSelectionConfirm}
            disabled={!lotSelectionDialog.selectedLotIds.length || 
              !validateQuantities(
                lotSelectionDialog.quantities, 
                lotSelectionDialog.selectedLotIds, 
                lotSelectionDialog.expectedQuantity
              )}
            variant="contained"
            color="primary"
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successNotification.open}
        autoHideDuration={4000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity="success" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successNotification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default observer(PrepareShipmentScreen);
