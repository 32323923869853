import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Chip,
  Button,
  Breadcrumbs,
  Link,
  Snackbar,
  Alert as MuiAlert
} from '@mui/material';
import { useStore } from '../../../../app/stores/store.ts';
import { observer } from "mobx-react-lite";
import agent from '../../../../app/api/agent.ts';
import { OrderDetail } from '../../../../app/models/orderDetail.ts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { styled } from '@mui/material/styles';
import { 
  Card, 
  CardContent, 
} from '@mui/material';
import { OrderStatus } from "../../../../app/models/order.ts";
import { ReturnDto } from '../../../../app/models/return.ts';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { alpha } from '@mui/material/styles';
import LoadingComponent from '../../../../app/layout/LoadingComponent.tsx';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import UpdateIcon from '@mui/icons-material/Update';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReturnType } from "../../../../app/models/return.ts";

interface ReturnLot {
  lotId: number;
  lotNumber: string;
  quantity: number;
  maxQuantity: number;
  selected: boolean;
}

interface ReturnItem {
  orderItemId: number;
  quantity: number;
  lots: ReturnLot[];
  selected: boolean;
}

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
  marginBottom: theme.spacing(3)
}));

const ReturnHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
  marginBottom: theme.spacing(3)
}));

const ReturnStatusCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
}));

const TimelineConnector = styled(Box)(({ theme }) => ({
  width: 2,
  height: 24,
  backgroundColor: theme.palette.divider,
  margin: '0 auto',
}));

const getStatusLabel = (status: OrderStatus): string => {
  const statusMap: { [key in OrderStatus]?: string } = {
    [OrderStatus.WaitingForApproval]: 'Pending',
    [OrderStatus.ReturnInProgress]: 'In Progress',
    [OrderStatus.ReturnCompleted]: 'Completed',
    [OrderStatus.Revoked]: 'Cancelled'
  };
  return statusMap[status] || 'Unknown';
};

const getStatusColor = (status: OrderStatus): 'default' | 'primary' | 'success' | 'error' | 'warning' => {
  const colorMap: { [key in OrderStatus]?: 'default' | 'primary' | 'success' | 'error' | 'warning' } = {
    [OrderStatus.WaitingForApproval]: 'warning',
    [OrderStatus.ReturnInProgress]: 'primary',
    [OrderStatus.ReturnCompleted]: 'success',
    [OrderStatus.Revoked]: 'error'
  };
  return colorMap[status] || 'default';
};

// Add a helper function to convert string status to OrderStatus enum
const parseStatus = (status: string): OrderStatus => {
  return OrderStatus[status as keyof typeof OrderStatus] || OrderStatus.WaitingForApproval;
};

const returnTypeIcons: { [key: string]: React.ReactNode } = {
  [ReturnType.FULL]: <AssignmentReturnIcon color="primary" />,
  [ReturnType.PARTIAL]: <FilterNoneIcon color="primary" />,
  [ReturnType.DAMAGED]: <BrokenImageIcon color="primary" />,
  [ReturnType.WRONG_ITEM]: <SwapHorizIcon color="primary" />,
  [ReturnType.QUALITY_ISSUE]: <ReportProblemIcon color="primary" />,
  [ReturnType.EXPIRED]: <UpdateIcon color="primary" />,
  [ReturnType.CUSTOMER_DISSATISFACTION]: <SentimentDissatisfiedIcon color="primary" />,
  [ReturnType.SHIPPING_ERROR]: <LocalShippingIcon color="primary" />,
  [ReturnType.OTHER]: <MoreHorizIcon color="primary" />
};

const ViewReturnScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { orderStore } = useStore();
  const { loadOrderItems, loadingInitial, loadOrderById } = orderStore;
  const navigate = useNavigate();

  const [state, setState] = useState({
    orderNumber: null as string | null,
    orderDetails: null as OrderDetail[] | null,
  });

  const [returnDetails, setReturnDetails] = useState({
    reason: '',
    notes: '',
    returnType: 'full',
  });

  const [returnItems, setReturnItems] = useState<ReturnItem[]>([]);

  const steps = ['Order Details', 'Select Items', 'Review & Submit'];
  const activeStep = 1; // You can make this dynamic based on the return process

  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });
  
  const {
    updateOrderStatus,
  } = orderStore;

  const [returnData, setReturnData] = useState<ReturnDto | null>(null);

  const [processing, setProcessing] = useState(false);

  const handleCompleteReturn = async () => {
    if (!id) return;
    
    setProcessing(true);
    try {
      // First process the return
      await agent.Returns.process(Number(id));
      
      // Then update the order status
      await updateOrderStatus(Number(id), OrderStatus.ReturnCompleted);
      
      setNotification({
        open: true,
        message: 'Return has been processed and marked as complete',
        severity: 'success'
      });

      // Refresh the return data to show updated processed date
      const updatedReturn = await agent.Returns.details(Number(id));
      setReturnData(updatedReturn);

      setTimeout(() => {
        navigate('/admin/warehouse/returns');
      }, 2000);
      
    } catch (error) {
      console.error("Error completing return:", error);
      setNotification({
        open: true,
        message: 'Failed to complete return. Please try again.',
        severity: 'error'
      });
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    const fetchReturnData = async () => {
      if (!id) return;

      try {
        const [orderItemsWithLots, returnDetails] = await Promise.all([
          agent.Orders.getOrderItemsWithLots(Number(id)),
          agent.Returns.details(Number(id))
        ]);

        setState(prev => ({
          ...prev,
          orderDetails: orderItemsWithLots
        }));

        if (returnDetails) {
          setReturnData(returnDetails);
          setReturnDetails({
            reason: returnDetails.reason || '',
            notes: returnDetails.notes || '',
            returnType: returnDetails.returnType.toLowerCase() || 'full',
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setNotification({
          open: true,
          message: 'Failed to load return details',
          severity: 'error'
        });
      }
    };

    loadOrderItems(Number(id)).then(() => {
      fetchReturnData();
    });
  }, [id, loadOrderItems]);

  useEffect(() => {
    if (returnData?.items && state.orderDetails?.items) {
      const updatedReturnItems = state.orderDetails.items.map(orderItem => {
        const returnItem = returnData.items.find(ri => ri.orderItemId === orderItem.orderItemId);
        
        return {
          orderItemId: orderItem.orderItemId,
          quantity: returnItem?.quantity || 0,
          lots: orderItem.lots.map(lot => {
            const returnLot = returnItem?.lots.find(rl => rl.lotNumber === lot.lotNumber);
            return {
              lotId: lot.lotId,
              lotNumber: lot.lotNumber,
              quantity: returnLot?.quantity || 0,
              maxQuantity: lot.caseQuantity + lot.unitQuantity,
              selected: !!returnLot
            };
          }),
          selected: !!returnItem
        };
      });

      setReturnItems(updatedReturnItems);
    }
  }, [returnData, state.orderDetails]);

  const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification(prev => ({ ...prev, open: false }));
  };

  if (loadingInitial) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  
  if (!state.orderDetails?.items?.length) return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">No items found for this order</Typography>
      <Button 
        variant="outlined" 
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/admin/warehouse/returns')}
        sx={{ mt: 2 }}
      >
        Back to Ship Management
      </Button>
    </Box>
  );

  return (
    <Box sx={{ p: 3, maxWidth: 1400, margin: '0 auto' }}>
      {/* Update Header */}
      <ReturnHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              View Return Details
            </Typography>
            <Breadcrumbs sx={{ color: 'inherit' }}>
              <Link 
                color="inherit" 
                href="/admin/warehouse/returns"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <AssignmentReturnIcon sx={{ mr: 0.5 }} fontSize="small" />
                Returns
              </Link>
              <Typography color="inherit">
                Order #{state.orderNumber}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/admin/warehouse/returns')}
          >
            Back to Returns
          </Button>
        </Box>
      </ReturnHeader>

      {state.orderDetails && returnData && (
        <>
          {/* Return Details Section */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <ReceiptLongIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Return Details</Typography>
                <Chip 
                  label={getStatusLabel(parseStatus(state.orderDetails.status))}
                  color={getStatusColor(parseStatus(state.orderDetails.status))}
                  sx={{ ml: 'auto' }}
                />
              </Box>

              <Grid container spacing={3}>
                {/* Return Type Card */}
                <Grid item xs={12} md={4}>
                  <ReturnStatusCard>
                    <Box sx={{ 
                      p: 1.5, 
                      borderRadius: '50%', 
                      bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1)
                    }}>
                      {returnTypeIcons[returnData.returnType] || <AssignmentReturnIcon />}
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Return Type
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {returnData.returnType.replace(/_/g, ' ').toLowerCase()
                          .replace(/\b\w/g, l => l.toUpperCase())}
                      </Typography>
                    </Box>
                  </ReturnStatusCard>
                </Grid>

                {/* Return Date Card */}
                <Grid item xs={12} md={4}>
                  <ReturnStatusCard>
                    <Box sx={{ 
                      p: 1.5, 
                      borderRadius: '50%', 
                      bgcolor: (theme) => alpha(theme.palette.info.main, 0.1)
                    }}>
                      <CalendarTodayIcon color="info" />
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Return Initiated
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {new Date(returnData.returnDate).toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </Typography>
                    </Box>
                  </ReturnStatusCard>
                </Grid>

                {/* Processed Date Card */}
                <Grid item xs={12} md={4}>
                  <ReturnStatusCard>
                    <Box sx={{ 
                      p: 1.5, 
                      borderRadius: '50%', 
                      bgcolor: (theme) => alpha(theme.palette.success.main, 0.1)
                    }}>
                      <CheckCircleIcon color="success" />
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Processing Status
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {returnData.processedAt 
                          ? new Date(returnData.processedAt).toLocaleDateString(undefined, {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })
                          : 'Pending Processing'}
                      </Typography>
                    </Box>
                  </ReturnStatusCard>
                </Grid>

                {/* Return Details */}
                <Grid item xs={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Return Reason
                    </Typography>
                    <Paper sx={{ 
                      p: 2, 
                      bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
                      border: '1px solid',
                      borderColor: 'divider',
                    }}>
                      <Typography>{returnData.reason}</Typography>
                    </Paper>
                  </Box>
                </Grid>

                {returnData.notes && (
                  <Grid item xs={12}>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Additional Notes
                      </Typography>
                      <Paper sx={{ 
                        p: 2, 
                        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
                        border: '1px solid',
                        borderColor: 'divider',
                      }}>
                        <Typography>{returnData.notes}</Typography>
                      </Paper>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Items Table */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <InventoryIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Return Items</Typography>
                <Typography variant="body2" color="text.secondary" sx={{ ml: 'auto' }}>
                  {returnData.items.length} items returned
                </Typography>
              </Box>

              <TableContainer component={Paper} sx={{ 
                borderRadius: 2,
                boxShadow: 'none',
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell align="right">Return Quantity</TableCell>
                      <TableCell>Lot Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {returnData.items.map((returnItem) => {
                      const orderItem = state.orderDetails.items.find(
                        item => item.orderItemId === returnItem.orderItemId
                      );
                      if (!orderItem) return null;

                      return (
                        <TableRow key={returnItem.orderItemId}>
                          <TableCell>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                              <img
                                // src={orderItem.productImageUrl}
                                src={`${process.env.REACT_APP_API_ROOT}${orderItem.productImageUrl}`}
                                alt={orderItem.productName}
                                style={{ width: 50, height: 50, objectFit: 'cover' }}
                              />
                              <Typography>{orderItem.productName}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{orderItem.productDescription}</TableCell>
                          <TableCell align="right">
                            <Typography variant="h6">
                              {returnItem.quantity}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              of {orderItem.quantity} {returnItem.isCaseQuantity ? 'Cases' : 'Units'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              {returnItem.lots.map((lot) => (
                                <Paper
                                  key={lot.id}
                                  elevation={0}
                                  sx={{
                                    p: 2,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    borderRadius: 2
                                  }}
                                >
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="subtitle2">
                                      Lot: {lot.lotNumber}
                                    </Typography>
                                    <Chip 
                                      label={`Quantity: ${lot.quantity}`}
                                      size="small"
                                      color="info"
                                      variant="outlined"
                                    />
                                  </Box>
                                </Paper>
                              ))}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>

          {/* Non-Returned Items Table */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <InventoryIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Non-Returned Items</Typography>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell align="right">Original Quantity</TableCell>
                      <TableCell>Lot Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.orderDetails.items.map((orderItem) => {
                      const returnItem = returnData.items.find(
                        item => item.orderItemId === orderItem.orderItemId
                      );

                      // Get non-returned lots and partially returned lots
                      const nonReturnedLots = orderItem.lots.filter(lot => {
                        const returnedLot = returnItem?.lots.find(rl => rl.lotNumber === lot.lotNumber);
                        // Include lots that weren't returned at all or were partially returned
                        if (!returnedLot) return true;
                        
                        const originalQuantity = lot.caseQuantity + lot.unitQuantity;
                        const returnedQuantity = returnedLot.quantity;
                        return returnedQuantity < originalQuantity;
                      });

                      // Skip if no lots to show
                      if (!nonReturnedLots.length) return null;

                      return (
                        <TableRow key={orderItem.orderItemId}>
                          <TableCell>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                              <img
                                src={`${process.env.REACT_APP_API_ROOT}${orderItem.productImageUrl}`}
                                // src={orderItem.productImageUrl}
                                alt={orderItem.productName}
                                style={{ width: 50, height: 50, objectFit: 'cover' }}
                                
                              />
                              <Typography>{orderItem.productName}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{orderItem.productDescription || 'No description available'}</TableCell>
                          <TableCell align="right">
                            <Typography variant="h6">
                              {orderItem.quantity}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {orderItem.byCase ? 'Cases' : 'Units'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              {nonReturnedLots.map((lot) => {
                                const returnedLot = returnItem?.lots.find(rl => rl.lotNumber === lot.lotNumber);
                                const originalQuantity = lot.caseQuantity + lot.unitQuantity;
                                const remainingQuantity = returnedLot 
                                  ? originalQuantity - returnedLot.quantity 
                                  : originalQuantity;

                                return (
                                  <Paper
                                    key={lot.lotId}
                                    elevation={0}
                                    sx={{
                                      p: 2,
                                      border: '1px solid',
                                      borderColor: 'divider',
                                      borderRadius: 2
                                    }}
                                  >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                      <Typography variant="subtitle2">
                                        Lot: {lot.lotNumber}
                                      </Typography>
                                      <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Chip 
                                          label={`Remaining: ${remainingQuantity}`}
                                          size="small"
                                          color="default"
                                          variant="outlined"
                                        />
                                        {returnedLot && (
                                          <Chip 
                                            label="Partially Returned"
                                            size="small"
                                            color="warning"
                                            variant="outlined"
                                          />
                                        )}
                                      </Box>
                                    </Box>
                                  </Paper>
                                );
                              })}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>

          {/* Return Status Section */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AssignmentReturnIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Return Status</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2" color="text.secondary">Status</Typography>
                  <Chip 
                    label={getStatusLabel(parseStatus(state.orderDetails.status))}
                    color={getStatusColor(parseStatus(state.orderDetails.status))}
                    sx={{ mt: 1 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2" color="text.secondary">Return Date</Typography>
                  <Typography>
                    {new Date(returnData.returnDate).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2" color="text.secondary">Processed Date</Typography>
                  <Typography>
                    {returnData.processedAt 
                      ? new Date(returnData.processedAt).toLocaleDateString() 
                      : 'Not processed yet'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </>
      )}

      <Paper 
        elevation={0} 
        sx={{ 
          p: 2, 
          mt: 3, 
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}>
          <Typography variant="body2" color="text.secondary">
            {processing ? 'Processing return...' : 'Ready to complete return?'}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate('/admin/warehouse/returns')}
              disabled={processing}
            >
              Back to Returns
            </Button>
            {processing && <LoadingComponent message="Processing return..." />}
            <Button
              variant="contained"
              color="primary"
              onClick={handleCompleteReturn}
              disabled={
                parseStatus(state.orderDetails?.status) === OrderStatus.ReturnCompleted ||
                parseStatus(returnData?.status) === OrderStatus.ReturnCompleted ||
                processing
              }
              startIcon={<CheckCircleIcon />}
            >
              Complete Return
            </Button>
          </Box>
        </Box>
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert 
          onClose={handleCloseNotification}
          severity={notification.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default observer(ViewReturnScreen);
