import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Grid,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { Order, OrderStatus, getOrderStatusLabel, getStatusChipColor } from "../../../../app/models/order.ts";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { OrderItem } from "../../../../app/models/orderItem.ts";
import agent from "../../../../app/api/agent.ts";
import { generateRefundInvoice, canGenerateRefundInvoice } from "../../../../app/util/invoiceUtils.ts";
//Added by Riniyad - requester Tony - to handle export to csv
const exportToExcel = (order: Order) => {
  const { orderNumber, orderDate, client, userName, itemsCount, orderItems } =
    order;

  const data: any[][] = [];

  // Add order-level information in two columns (Label and Value)
  data.push(["Order Number", orderNumber]);
  data.push(["Order Date", formatDate(orderDate)]);
  data.push(["Client", client.name]);
  data.push(["Sales Agent", userName]);
  data.push(["Total Items", itemsCount]);
  data.push(["Order Total", order.subTotal]);

  // a blank row to separate order info from order items
  data.push([]);

  // header row for order items
  data.push([
    "Product Code",
    "Product Name",
    "Price",
    "Quantity",
    "Case/Unit",
    "Total Price",
  ]);

  // Add each order
  orderItems.forEach((item: OrderItem) => {
    data.push([
      item.product.code,
      item.product.name,
      item.unitPrice,
      item.quantity,
      item.byCase ? "case" : "unit",
      item.totalPrice,
    ]);
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Order Details");
  const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([buffer], { type: "application/octet-stream" });

  saveAs(blob, `${orderNumber}.xlsx`);
};

const generateIMPFile = (order) => {
  if (!order || !order.orderItems || order.orderItems.length === 0) {
    console.error("Order or order items are not available");
    return;
  }

  let impFileContent = "";

  // 1. Add version section
  impFileContent += `<Version>\n"12001", "1"\n</Version>\n\n`;

  // 2. Add invoice details (SalInvoice section)
  const formattedDate = dayjs().format("MM-DD-YY"); // format date as MM-DD-YY
  impFileContent += `<SalInvoice>\n`;

  // Client details
  impFileContent += `"${order.client.name}", , ,, ,, , ,, , , ,\n`;
  const distinctProductCount = new Set(
    order.orderItems.map((item) => item.product.code)
  ).size;
  // Order details
  impFileContent += `"${distinctProductCount}","${
    order.orderNumber
  }", ,"${formattedDate} ","0", ,"${order.subTotal.toFixed(2)}","0.00"\n`;

  // Item details for each item
  order.orderItems.forEach((item) => {
    impFileContent += `"${item.product.code}","${item.quantity.toFixed(
      4
    )}","${item.unitPrice.toFixed(4)}","${item.totalPrice.toFixed(2)}"`;

    if (item.taxMappings) {
      item.taxMappings.forEach((mapping) => {
        const rate =
          mapping.overrideRate === null
            ? mapping.tax.rate
            : mapping.overrideRate;
        const amount = (item.totalPrice * rate) / 100.0;
        impFileContent += `,"${mapping.tax.code}","${
          mapping.tax.isIncluded ? "1" : "0"
        }","${mapping.tax.isRefunded ? "1" : "0"}","${rate.toFixed(
          3
        )}","${amount.toFixed(2)}"`;
      });
    }

    impFileContent += "\n";
  });

  impFileContent += `</SalInvoice>\n`;

  // Create a Blob from the content and trigger file download
  const blob = new Blob([impFileContent], { type: "text/plain;charset=utf-8" });
  saveAs(blob, `${order.orderNumber}.imp`);
};

const OrderList: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadOrders,
    approveOrder,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    isApprovedFilter,
  } = orderStore;
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [OrderToDelete, setProductToDelete] = React.useState<number | null>(
    null
  );
  const [loading, setLoading] = React.useState({
    delete: false,
    toggle: false,
  });

  const [outOfStockDialog, setOutOfStockDialog] = useState({
    open: false,
    items: [] as any[],
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrders();
  };
  const handleDeleteOrder = async (orderId: number) => {
    try {
      setDeleteDialogOpen(true); // Open the dialog
      setProductToDelete(orderId); // Set the order to delete
    } catch (error) {
      console.error("Error deleting order:", error);
      setErrorMessage("An error occurred while deleting the order");
    }
  };

  const handleConfirmDelete = async () => {
    if (!OrderToDelete) return;
    setLoading((prev) => ({ ...prev, delete: true }));
    await orderStore.deleteOrder(OrderToDelete); // Call delete function from the store
    await navigateToHome(1); // Refresh the order list
    setLoading((prev) => ({ ...prev, delete: false }));
    setDeleteDialogOpen(false); // Close the dialog
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const handleToggle = async (order) => {
    try {
      if (loadingInitial) return;

      const stockCheckResult = await orderStore.checkOrderStock(order);

      if (!stockCheckResult.success) {
        setOutOfStockDialog({
          open: true,
          items: stockCheckResult.outOfStockItems,
        });

        await orderStore.updateOrderStatus(order, OrderStatus.OutOfStock);
      } else {
        await approveOrder(order);

        // Automatically assign lots after approval
        try {
          await agent.Orders.assignLots(order);
          setSuccessMessage("Order approved and lots assigned successfully");
        } catch (error) {
          console.error("Error assigning lots:", error);
          setErrorMessage("Order approved but failed to assign lots");
        }
      }

      await loadOrders();
    } catch (error) {
      console.error("Error processing order:", error);
      setErrorMessage("An error occurred while processing the order");
    }
  };

  const handleRevoke = async (id: number) => {
    try {
      if (loadingInitial) return;
      await orderStore.revokeOrder(id);
      await loadOrders();
      setSuccessMessage("Order revoked successfully");
    } catch (error) {
      console.error("Error revoking order:", error);
      setErrorMessage("An error occurred while revoking the order");
    }
  };

  const handleAddItemstoOrder = (order: Order) => {
    const serializedOrder = JSON.parse(JSON.stringify(order));
    navigate(
      `/admin/addorderitem?orderId=${order.id}&clientId=${order.client.id} `,
      { state: { order: serializedOrder } }
    );
  };

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  const handleCloseDialog = () => {
    setOutOfStockDialog({
      open: false,
      items: [],
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessMessage(null)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorMessage(null)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {loadingInitial ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper
            elevation={2}
            sx={{
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="medium">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Order Number
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Order Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Client
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        User
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Sub Total
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Items
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Order Items
                      </Typography>
                    </TableCell>
                    {isApprovedFilter ? (
                      <>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "white", fontWeight: "bold" }}
                          >
                            Approve
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "white", fontWeight: "bold" }}
                          >
                            Add Items to Order
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "white", fontWeight: "bold" }}
                          >
                            Delete Order
                          </Typography>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "white", fontWeight: "bold" }}
                          >
                            Revoke
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "white", fontWeight: "bold" }}
                          >
                            Export to Excel
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "white", fontWeight: "bold" }}
                          >
                            Generate .IMP File
                          </Typography>
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "white", fontWeight: "bold" }}
                      >
                        Refund Invoice
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders &&
                    orders.map((order) => (
                      <TableRow
                        key={order.id}
                        sx={{
                          "&:hover": {
                            bgcolor: "action.hover",
                            transition: "background-color 0.2s ease",
                          },
                          borderBottom: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <TableCell>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "monospace",
                              fontWeight: "medium",
                            }}
                          >
                            {order.orderNumber}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {formatDate(order.orderDate)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "medium" }}
                          >
                            {order.client.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {order.userName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            color="primary.main"
                            sx={{ fontWeight: "medium" }}
                          >
                            ${order.subTotal}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {order.itemsCount}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={getOrderStatusLabel(order.status)}
                            color={getStatusChipColor(order.status)}
                            size="small"
                            sx={{ fontWeight: 'medium' }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            component={Link}
                            to={`${order.id}`}
                            sx={{
                              color: "info.main",
                              "&:hover": {
                                bgcolor: "info.lighter",
                              },
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </TableCell>

                        {!isApprovedFilter ? (
                          <>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="warning"
                                size="small"
                                onClick={() => handleRevoke(order.id!)}
                                disabled={order.status === OrderStatus.Shipped || order.status===OrderStatus.ReturnCompleted || order.status===OrderStatus.ReturnInProgress}
                                sx={{
                                  minWidth: "100px",
                                  "&:hover": {
                                    bgcolor: "warning.dark",
                                  },
                                }}
                              >
                                Revoke
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => exportToExcel(order)}
                                sx={{
                                  bgcolor: "success.main",
                                  "&:hover": {
                                    bgcolor: "success.dark",
                                  },
                                }}
                              >
                                Export
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => generateIMPFile(order)}
                                sx={{
                                  bgcolor: "info.main",
                                  "&:hover": {
                                    bgcolor: "info.dark",
                                  },
                                }}
                              >
                                .IMP
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => generateRefundInvoice(order.id, JSON.parse(JSON.stringify(order)))}
                                disabled={!canGenerateRefundInvoice(order.status)}
                                sx={{
                                  bgcolor: "secondary.main",
                                  "&:hover": {
                                    bgcolor: "secondary.dark",
                                  },
                                }}
                              >
                                Refund Invoice
                              </Button>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              <Button onClick={() => handleToggle(order.id!)}>
                                Approve
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddItemstoOrder(order)}
                              >
                                Add Items
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleDeleteOrder(order.id!)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              count={pagination?.totalPages ?? 0}
              page={pagination?.currentPage ?? 1}
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Box>
        </>
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Order?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={outOfStockDialog.open}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            bgcolor: "#525252",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h6">Insufficient Stock Alert</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
            The following items don't have sufficient stock to fulfill the
            order:
          </Typography>
          <List>
            {outOfStockDialog.items.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: 1,
                  backgroundColor: "#f8f8f8",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="h6" color="primary">
                      {item.product.name}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      Code: {item.product.code}
                    </Typography>
                  </Box>

                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 1.5,
                          backgroundColor: "#e3f2fd",
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Requested
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {item.quantity}
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ ml: 0.5 }}
                          >
                            {item.byCase ? "cases" : "units"}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 1.5,
                          backgroundColor: "#fff3e0",
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Available
                        </Typography>
                        <Typography variant="h6" color="warning.main">
                          {item.availableQuantity}
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ ml: 0.5 }}
                          >
                            {item.byCase ? "cases" : "units"}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 1.5,
                          backgroundColor: "#ffebee",
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Shortage
                        </Typography>
                        <Typography variant="h6" color="error">
                          {item.quantity - item.availableQuantity}
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ ml: 0.5 }}
                          >
                            {item.byCase ? "cases" : "units"}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: "1px solid #e0e0e0" }}>
          <Button onClick={handleCloseDialog} variant="contained" size="large">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default observer(OrderList);
