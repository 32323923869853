import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Formik } from "formik";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  InputAdornment,
  Alert,
  Snackbar,
} from "@mui/material";
import * as Yup from "yup";
import { ProductFormValues } from "../../../../app/models/product.ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyTextInput from "../../../../app/common/form/MyTextInput.tsx";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import Delete from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import agent from "../../../../app/api/agent.ts";
import {
  ProductLot,
  ProductLotClass,
} from "../../../../app/models/ProductLot.ts";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

const enum DISPLAY_NAMES {
  name = "Name",
  code = "Code",
  description = "Description",
  unitPrice = "Unit Price",
  unitWeight = "Unit Weight",
  itemsInCase = "Items in Case",
  casePrice = "Case Price",
  itemsInStock = "Items in Stock",
  casesInStock = "Cases in Stock",
  categoryId = "Category",
  manufacturerId = "Manufacturer",
  category = "Category Name",
  manufacturer = "Manufacturer Name",
}

const enum FIELD_NAMES {
  name = "name",
  code = "code",
  description = "description",
  unitPrice = "unitPrice",
  unitWeight = "unitWeight",
  itemsInCase = "itemsInCase",
  casePrice = "casePrice",
  itemsInStock = "itemsInStock",
  casesInStock = "casesInStock",
  categoryId = "categoryId",
  manufacturerId = "manufacturerId",
  category = "categoryName",
  manufacturer = "manufacturerName",
}

const InventoryProductDetails: React.FC = () => {
  const navigate = useNavigate();
  const { productStore } = useStore();
  const {
    loadingInitial,
    loadingFilters,
    editMode,
    categories,
    manufacturers,
    pagingParams,
    setPagingParams,
    loadActiveCategories,
    loadActiveManufacturers,
    loadProductById,
    updateProduct,
    saveProduct,
    setEditMode,
  } = productStore;

  const [product, setProduct] = useState<ProductFormValues | null>(null);

  const { id } = useParams();

  const [, /*selectedFile*/ setSelectedFile] = useState<File | null>(null);
  const [previewSource, setPreviewSource] = useState<string | null>(null);

  const [productQuantities, setProductQuantities] = useState<ProductLot[]>([]);

  const [isQuantityEdited, setIsQuantityEdited] = useState(false);
  const [isQuantityValid, setIsQuantityValid] = useState(true);

  const [filterValue, setFilterValue] = useState("");

  // Add these state variables after other useState declarations
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const [tempIdCounter, setTempIdCounter] = useState(-1);

  const [isInitialized, setIsInitialized] = useState(false);

  // Fetch product lots
  useEffect(() => {
    const fetchProductLots = async (productId: number) => {
      try {
        const lots = await agent.ProductLots.getByProductNumber(productId);

        if (lots) {
          setProductQuantities(
            lots.map(
              (lot) =>
                new ProductLotClass(
                  lot.id,
                  lot.productId,
                  lot.lotNumber,
                  lot.sku,
                  lot.unitPrice,
                  lot.unitWeight,
                  lot.itemsInCase,
                  lot.casePrice,
                  lot.notes,
                  new Date(lot.createdAt),
                  new Date(lot.updatedAt),
                  lot.isActive,
                  lot.caseQuantity,
                  lot.unitQuantity,
                  lot.warehouseLocation,
                  lot.expiryDate ? new Date(lot.expiryDate) : new Date()
                )
            )
          );
        }
      } catch (error) {
        console.error("Failed to fetch product lots", error);
      }
    };

    if (id) {
      const productId = parseInt(id, 10); // Convert id to integer
      if (!isNaN(productId)) {
        fetchProductLots(productId);
      }
    }
  }, [id]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    previewFile(file);
    setSelectedFile(file);
  };

  const previewFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result as string);
    };
  };

  const handleClearImage = () => {
    setSelectedFile(null);
    setPreviewSource(null);
  };

  const navigateToHome = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: pagingParams.pageSize,
    });
    navigate("/admin/warehouse/inventory-control/");
  };

  const handleCreateOrEditProduct = (product: ProductFormValues) => {
    //if (!product.id) product.id = uuid();
    const formData = new FormData();
    Object.keys(product).forEach((key) => {
      formData.append(key, product[key]);
    });

    if (editMode) {
      updateProduct(product.id!, formData).then(() => navigateToHome());
    } else {
      saveProduct(formData).then(() => navigateToHome());
    }
  };

  // Split the initialization effect into two separate effects
  useEffect(() => {
    if (!categories?.length || !manufacturers?.length) {
      loadActiveCategories();
      loadActiveManufacturers();
    }
  }, [
    categories?.length,
    manufacturers?.length,
    loadActiveCategories,
    loadActiveManufacturers,
  ]);

  useEffect(() => {
    if (isInitialized) return; // Skip if already initialized

    if (id) {
      loadProductById(parseFloat(id)).then((product) => {
        setProduct(new ProductFormValues(product));
        if (product?.imageUrl) {
          setPreviewSource(
            `${process.env.REACT_APP_API_ROOT}${product.imageUrl}`
          );
        }
      });
      setEditMode(true);
    } else {
      const newProduct = new ProductFormValues();
      if (categories?.length > 0) {
        newProduct.categoryId = categories[0].id;
      }
      if (manufacturers?.length > 0) {
        newProduct.manufacturerId = manufacturers[0].id;
      }
      setProduct(newProduct);
      setEditMode(false);
      setProductQuantities([]);
    }

    setIsInitialized(true);
  }, [
    id,
    categories,
    manufacturers,
    isInitialized,
    loadProductById,
    setEditMode,
  ]);

  const validationSchema_add = Yup.object({
    name: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    unitPrice: Yup.number().nullable(),
    unitWeight: Yup.number().nullable(),
    itemsInCase: Yup.number().nullable(),
    casePrice: Yup.number().nullable(),
    itemsInStock: Yup.number().required("Required"),
    casesInStock: Yup.number().required("Required"),
    categoryId: Yup.string().required("Required"),
    manufacturerId: Yup.string().required("Required"),
  });

  const validationSchema_edit = Yup.object({
    name: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    unitPrice: Yup.number().nullable(),
    unitWeight: Yup.number().nullable(),
    itemsInCase: Yup.number().nullable(),
    casePrice: Yup.number().nullable(),
    itemsInStock: Yup.number().required("Required"),
    casesInStock: Yup.number().required("Required"),
    manufacturerId: Yup.string().required("Required"),
  });

  const validateProductQuantities = useCallback((quantities: ProductLot[]) => {
    for (const lot of quantities) {
      if (!lot.lotNumber || lot.unitQuantity < 0 || lot.caseQuantity < 0) {
        return false;
      }
    }
    return true;
  }, []);

  const handleQuantityChange = useCallback(
    (newRow: any, oldRow: any) => {
      const updatedQuantities = productQuantities.map((q) => {
        if (q.id === newRow.id) {
          const updatedLot = new ProductLotClass(
            newRow.id,
            newRow.productId,
            newRow.lotNumber,
            newRow.sku,
            newRow.unitPrice,
            newRow.unitWeight,
            newRow.itemsInCase,
            newRow.casePrice,
            newRow.notes,
            new Date(newRow.createdAt),
            new Date(newRow.updatedAt),
            newRow.isActive,
            newRow.caseQuantity,
            newRow.unitQuantity,
            newRow.warehouseLocation,
            newRow.expiryDate ? new Date(newRow.expiryDate) : new Date()
          );
          return updatedLot;
        }
        return q;
      });

      setProductQuantities(updatedQuantities);
      setIsQuantityEdited(true);
      setIsQuantityValid(validateProductQuantities(updatedQuantities));
      return newRow;
    },
    [productQuantities, validateProductQuantities]
  );

  const updateQuantityInStock = (quantities) => {
    return quantities.map((lot) => ({
      ...lot,
      quantityInStock: lot.quantity,
    }));
  };

  const handleDeleteProductLot = async (id: number) => {
    try {
      if (id > 0) {
        await agent.ProductLots.delete(id);
      }
      setProductQuantities(productQuantities.filter((lot) => lot.id !== id));
      setIsQuantityEdited(productQuantities.length > 1);
    } catch (error) {
      console.error("Failed to delete product lot", error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "lotNumber",
      headerName: "Lot Number",
      width: 120,
      editable: true,
    },
    { field: "sku", headerName: "SKU", width: 100, editable: true },
    {
      field: "unitQuantity",
      headerName: "Unit Quantity",
      type: "number",
      width: 120,
      editable: true,
      // Add validation for non-negative numbers
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "caseQuantity",
      headerName: "Case Quantity",
      type: "number",
      width: 120,
      editable: true,
      // Add validation for non-negative numbers
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    // { field: 'unitPrice', headerName: 'Unit Price', type: 'number', width: 100, editable: true, valueFormatter: ({ value }) => value ? `$${value}` : '-' },
    // { field: 'casePrice', headerName: 'Case Price', type: 'number', width: 100, editable: true, valueFormatter: ({ value }) => value ? `$${value}` : '-' },
    // { field: 'itemsInCase', headerName: 'Items in Case', type: 'number', width: 120, editable: true, valueFormatter: ({ value }) => value || '-' },
    // { field: 'unitWeight', headerName: 'Unit Weight', type: 'number', width: 100, editable: true, valueFormatter: ({ value }) => value ? `${value} lbs` : '-' },
    {
      field: "warehouseLocation",
      headerName: "WH Location",
      width: 120,
      editable: true,
    },
    { field: "notes", headerName: "Notes", width: 150, editable: true },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      width: 150,
      editable: true,
      type: "date",
      renderCell: ({ value }) => formatDate(value),
      // valueGetter: (params) => new Date(params.row.expiryDate)
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 150,
      renderCell: ({ value }) => formatDate(value),
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      width: 150,
      renderCell: ({ value }) => formatDate(value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleDeleteProductLot(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleSaveQuantities = () => {
    if (validateProductQuantities(productQuantities)) {
      if (!editMode)
        setProductQuantities(updateQuantityInStock(productQuantities));
      saveProductQuantities();
    }
  };

  const saveProductQuantities = async () => {
    try {
      // Separate temporary (new) and existing records
      const newProductLots = productQuantities.filter((lot) => lot.id < 0);
      const existingProductLots = productQuantities.filter((lot) => lot.id > 0);

      // Batch update existing lots instead of updating one by one
      if (existingProductLots.length > 0) {
        await agent.ProductLots.batchUpdate(existingProductLots);
      }

      // Handle new lots
      for (const lot of newProductLots) {
        const exists = await agent.ProductLots.checkLotExists(
          lot.productId,
          lot.lotNumber
        );

        if (exists) {
          setSnackbarMessage(
            `Lot number ${lot.lotNumber} already exists. Merging the lot.`
          );
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }

      // Create new lots in batch if any exist
      if (newProductLots.length > 0) {
        await agent.ProductLots.create(newProductLots);
      }

      // Refresh product lots after saving
      if (product?.id) {
        const updatedLots = await agent.ProductLots.getByProductNumber(product.id);
        setProductQuantities(
          updatedLots.map(
            (lot) =>
              new ProductLotClass(
                lot.id,
                lot.productId,
                lot.lotNumber,
                lot.sku,
                lot.unitPrice,
                lot.unitWeight,
                lot.itemsInCase,
                lot.casePrice,
                lot.notes,
                new Date(lot.createdAt),
                new Date(lot.updatedAt),
                lot.isActive,
                lot.caseQuantity,
                lot.unitQuantity,
                lot.warehouseLocation,
                lot.expiryDate ? new Date(lot.expiryDate) : new Date()
              )
          )
        );
      }

      setSnackbarMessage("Product quantities saved successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      setIsQuantityEdited(false);
    } catch (error) {
      console.error("Failed to save product quantities", error);
      setSnackbarMessage("Failed to save product quantities");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const filteredProductQuantities = useMemo(() => {
    return productQuantities.filter((lot) => {
      const searchTerm = filterValue.toLowerCase();
      return (
        lot.lotNumber.toLowerCase().includes(searchTerm) ||
        lot.notes?.toLowerCase().includes(searchTerm) ||
        lot.unitQuantity.toString().includes(searchTerm) ||
        lot.caseQuantity.toString().includes(searchTerm) ||
        lot.sku?.toLowerCase().includes(searchTerm) ||
        lot.warehouseLocation?.toLowerCase().includes(searchTerm)
      );
    });
  }, [productQuantities, filterValue]);

  if (editMode && (loadingInitial || !product || loadingFilters || !categories))
    return <CircularProgress />;

  const handleAddQuantity = () => {
    const defaultExpiryDate = new Date();
    defaultExpiryDate.setMonth(defaultExpiryDate.getMonth() + 1);  // Add one month to current date
    
    const newProductLot = new ProductLotClass(
      tempIdCounter,
      product?.id || 0,
      "",
      "",
      0,
      0,
      0,
      0,
      "",
      new Date(),
      new Date(),
      true,
      0,
      0,
      "",
      defaultExpiryDate  // Set default expiry date to one month from now
    );

    setProductQuantities([...productQuantities, newProductLot]);
    setTempIdCounter((prevCounter) => prevCounter - 1);
  };

  return (
    product && (
      <>
        <h1>{editMode ? "Edit Product" : "Add Product"}</h1>
        <Formik
          initialValues={product}
          validationSchema={
            editMode ? validationSchema_edit : validationSchema_add
          }
          onSubmit={(values) => {
            handleCreateOrEditProduct(values);
          }}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            setFieldValue,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={10}>
                <Grid item xs={6}>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <MyTextInput
                        name={FIELD_NAMES.name}
                        label={DISPLAY_NAMES.name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        name={FIELD_NAMES.code}
                        label={DISPLAY_NAMES.code}
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        name={FIELD_NAMES.description}
                        label={DISPLAY_NAMES.description}
                        fullWidth
                        rows={3}
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        name={FIELD_NAMES.unitPrice}
                        label={`${DISPLAY_NAMES.unitPrice} (Optional)`}
                        type="number"
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        name={FIELD_NAMES.unitWeight}
                        label={`${DISPLAY_NAMES.unitWeight} (Optional)`}
                        type="number"
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        name={FIELD_NAMES.itemsInCase}
                        label={`${DISPLAY_NAMES.itemsInCase} (Optional)`}
                        type="number"
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        name={FIELD_NAMES.casePrice}
                        label={`${DISPLAY_NAMES.casePrice} (Optional)`}
                        type="number"
                      />
                    </Grid>
                    {!editMode && (
                      <Grid item>
                        <MyTextInput
                          name={FIELD_NAMES.categoryId}
                          label={DISPLAY_NAMES.categoryId}
                          select
                          list={categories}
                          fullWidth
                        />
                      </Grid>
                    )}
                    {/* Add new fields for Manufacturer and Category (disabled) */}
                    {editMode && (
                      <>
                        <Grid item>
                          <MyTextInput
                            name={FIELD_NAMES.manufacturer}
                            label={DISPLAY_NAMES.manufacturer}
                            defaultValue={product?.manufacturer?.name || "N/A"}
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            name={FIELD_NAMES.category}
                            label={DISPLAY_NAMES.category}
                            defaultValue={product?.category?.name || "N/A"}
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </>
                    )}
                    {!editMode && (
                      <Grid item>
                        <MyTextInput
                          name={FIELD_NAMES.manufacturerId}
                          label={DISPLAY_NAMES.manufacturerId}
                          select
                          list={manufacturers}
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={4} direction={"column"}>
                    <Grid item>
                      {previewSource ? (
                        <div
                          style={{
                            width: "500px",
                            height: "400px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            border: "1px dashed gray",
                          }}
                        >
                          <img
                            src={previewSource}
                            alt="Product preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "500px",
                            height: "400px",
                            border: "1px dashed gray",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          No image selected - Please upload image
                        </div>
                      )}
                    </Grid>
                    <Grid item>
                      <Grid container spacing={5}>
                        <Grid item>
                          {!editMode || (editMode && !previewSource) ? (
                            <>
                              <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="raised-button-file"
                                type="file"
                                onChange={(event) => {
                                  handleFileChange(event);
                                  setFieldValue(
                                    "image",
                                    event.currentTarget.files![0]
                                  );
                                }}
                              />
                              <label htmlFor="raised-button-file">
                                <Button
                                  variant="outlined"
                                  component="span"
                                  startIcon={<UploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                            </>
                          ) : null}
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClearImage}
                            startIcon={<Delete />}
                          >
                            Clear Image
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {editMode && ( // Only render this section in edit mode
                    <>
                      <h2>Product Quantities</h2>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Filter by lot number, notes, or quantities"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        style={{ marginBottom: "20px" }}
                      />
                      <DataGrid
                        rows={filteredProductQuantities}
                        columns={columns}
                        checkboxSelection
                        disableSelectionOnClick
                        autoHeight
                        editMode="row"
                        hideFooterPagination={true}
                        processRowUpdate={handleQuantityChange}
                        sx={{
                          "& .MuiDataGrid-row.Mui-selected": {
                            backgroundColor: "#e3f2fd !important", // Light blue background
                          },
                          "& .MuiDataGrid-row.Mui-selected:hover": {
                            backgroundColor: "#bbdefb !important", // Slightly darker blue on hover
                          },
                          "& .MuiDataGrid-row.Mui-editing": {
                            backgroundColor: "#e8f5e9 !important", // Light green background for editing
                            boxShadow: "0 0 5px rgba(0,0,0,0.2)", // Subtle shadow for depth
                          },
                        }}
                      />

                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleAddQuantity}
                        style={{ marginTop: "5px" }}
                      >
                        Add Quantity
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveQuantities}
                        style={{ marginTop: "5px" }}
                        disabled={!isQuantityEdited || !isQuantityValid}
                      >
                        Save Quantities
                      </Button>
                    </>
                  )}
                </Grid>

                <Grid item>
                  <Grid container spacing={8}>
                    <Grid item>
                      <Button
                        startIcon={editMode ? <SaveIcon /> : undefined}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !dirty || !isValid}
                      >
                        {editMode ? "Save" : "Add"}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        component={Link}
                        to={`/admin/warehouse/inventory-control/`}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    )
  );
};

export default observer(InventoryProductDetails);
