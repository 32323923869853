import { makeAutoObservable, runInAction } from "mobx";
import { Return, ReturnStatus } from "../models/return";
import agent from "../api/agent.ts";
import { PaginatedResults } from "../models/pagination.ts";
import { OrderItem } from "../models/orderItem.ts";

export default class ReturnStore {
  returns: Return[] = [];
  selectedReturn: Return | null = null;
  loading = false;
  loadingInitial = false;
  pagination: PaginatedResults<Return> | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadReturns = async (pageNumber = 1, pageSize = 10, params = new URLSearchParams()) => {
    this.setLoadingInitial(true);
    try {
      params.set('pageNumber', pageNumber.toString());
      params.set('pageSize', pageSize.toString());
      const result = await agent.Returns.list(params);
      runInAction(() => {
        this.returns = result.data;
        this.pagination = result;
      });
    } catch (error) {
      console.error('Error loading returns:', error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadReturn = async (id: number) => {
    this.loading = true;
    try {
      const returnData = await agent.Returns.details(id);
      runInAction(() => {
        this.selectedReturn = returnData;
      });
    } catch (error) {
      console.error('Error loading return:', error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateReturnStatus = async (id: number, status: ReturnStatus, items: OrderItem[]) => {
    this.loading = true;
    try {
      await agent.Returns.updateStatus(id, status);
      runInAction(() => {
        // Update the returns state if needed
      });
      return true;
    } catch (error) {
      console.error('Error updating return status:', error);
      return false;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
} 