import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Chip,
} from "@mui/material";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";

const ClientDetails: React.FC<{}> = () => {
  const { clientStore } = useStore();
  const { loadClientById, loadingInitial, selectedClient } = clientStore;

  const { id } = useParams();

  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  };

  useEffect(() => {
    const loadClientDetails = async () => {
      await loadClientById(parseFloat(id!));
    };

    if (id) {
      loadClientDetails();
    }
  }, [id, loadClientById]);

  if (loadingInitial || !selectedClient) return <CircularProgress />;

  const {
    code,
    name,
    email,
    phoneNumber,
    address,
    address2,
    city,
    province,
    postalCode,
    isActive
  } = selectedClient;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Client Details
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {/* Basic Information */}
            <Grid item xs={12}>
              <Typography variant="h6" color="primary" gutterBottom>
                Basic Information
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 1 }}>
                <Typography variant="subtitle1">
                  Status:
                </Typography>
                <Chip
                  label={isActive ? "Active" : "Inactive"}
                  color={isActive ? "success" : "default"}
                  size="small"
                />
              </Box>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>Code:</strong> {code}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>Name:</strong> {name}
              </Typography>
            </Grid>

            {/* Contact Information */}
            <Grid item xs={12}>
              <Typography variant="h6" color="primary" gutterBottom>
                Contact Information
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>Email:</strong> {email}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>Phone:</strong> {formatPhoneNumber(phoneNumber)}
              </Typography>
            </Grid>

            {/* Address Information */}
            <Grid item xs={12}>
              <Typography variant="h6" color="primary" gutterBottom>
                Address Information
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>Address Line 1:</strong> {address}
              </Typography>
              {address2 && (
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                  <strong>Address Line 2:</strong> {address2}
                </Typography>
              )}
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>City:</strong> {city}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>Province:</strong> {province.name}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                <strong>Postal Code:</strong> {postalCode}
              </Typography>
            </Grid>
          </Grid>

          {/* Action Buttons */}
          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/admin/clients/edit/${id}`}
            >
              Edit Client
            </Button>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={`/admin/clients`}
            >
              Back To Clients
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default observer(ClientDetails);
