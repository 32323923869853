import { makeAutoObservable, runInAction } from "mobx";
import { Client, ClientFormValues } from "../models/client";
import agent from "../api/agent.ts";
import { Pagination, PagingParams } from "../models/pagination.ts";
//@riniyad
export class Province {
  id: number = 0;
  name: string = "";
}

interface SearchParams {
  name?: string;
  code?: string;
  phone?: string;
  address?: string;
}

export default class ClientStore {
  clients: Client[] = [];
  selectedClient: Client | undefined = undefined;
  editMode = false;
  loadingInitial = true;
  submitting = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  isActiveFilter = true;
  nameFilter = "";
  provinces: Province[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  //@riniyad
  loadProvinces = async () => {
    try {
      const provinces = await agent.Provinces.list();
      runInAction(() => {
        this.provinces = provinces;
      });
    } catch (error) {
      console.error("Failed to load provinces", error);
    }
  };

  loadClients = async (params?: SearchParams) => {
    try {
      // Create a new URLSearchParams instance
      let queryParams = new URLSearchParams();

      // Append the filter values if they exist
      if (params?.name) queryParams.append("name", params.name);
      if (params?.code) queryParams.append("code", params.code);
      if (params?.phone) queryParams.append("phone", params.phone);
      if (params?.address) queryParams.append("address", params.address);

      // Add default params
      queryParams.append("isActive", String(this.isActiveFilter));
      queryParams.append("pageNumber", this.pagingParams.pageNumber.toString());
      queryParams.append("pageSize", this.pagingParams.pageSize.toString());

      const response = await agent.Clients.list(queryParams);

      runInAction(() => {
        this.setClients(response.data);
        this.setPagination(response.pagination);
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  loadActiveClients = async () => {
    this.setLoadingInitial(true);
    try {
      // Create a new URLSearchParams instance
      let params = new URLSearchParams();
      params.append("isActive", String(this.isActiveFilter));

      const response = await agent.Clients.list(params);

      runInAction(() => {
        this.setClients(response.data);
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  getClientById = (id: number) => {
    return this.clients.find((client) => client.id === id);
  };

  saveClient = async (client: ClientFormValues) => {
    this.submitting = true;
    try {
      await agent.Clients.create(client);
      this.setSubmitting(false);
      this.setEditMode(false);
    } catch (error) {
      console.log(error);
      this.setSubmitting(false);
    }
  };

  updateClient = async (client: ClientFormValues) => {
    this.submitting = true;
    try {
      await agent.Clients.edit(client);
      runInAction(() => {
        let updateClient = {
          ...this.getClientById(client.id!),
          client,
        };
        this.selectedClient = updateClient as Client;
      });
      this.setSubmitting(false);
      this.setEditMode(false);
    } catch (error) {
      console.log(error);
      this.setSubmitting(false);
    }
  };

  loadClientById = async (id: number) => {
    let client = this.getClientById(id);
    if (client) {
      this.selectedClient = client;
      return client;
    } else {
      this.setLoadingInitial(true);
      try {
        const client = await agent.Clients.details(id);
        runInAction(() => {
          this.selectedClient = client;
        });
        this.setLoadingInitial(false);
        return client;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  deleteClient = async (id: number) => {
    this.setSubmitting(true);
    try {
      await agent.Clients.delete(id);
      runInAction(() => {
        this.clients = this.clients.filter((client) => client.id !== id);
        this.setSubmitting(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setSubmitting(false);
      });
    }
  };

  toggleActive = async (id: number) => {
    this.setSubmitting(true);
    try {
      await agent.Clients.toggleActive(id);
      runInAction(() => {
        this.clients.find((a) => a.id === id)!.isActive = !this.clients.find(
          (a) => a.id === id
        )!.isActive;
        this.setSubmitting(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setSubmitting(false);
      });
    }
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setLoadingInitial = (value: boolean) => {
    this.loadingInitial = value;
  };

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setNameFilter = (name: string) => {
    this.nameFilter = name;
  };

  setIsActiveFilter = (isActive: boolean) => {
    this.isActiveFilter = isActive;
  };

  setEditMode = (value: boolean) => {
    this.editMode = value;
  };

  setClients = (clients: Client[]) => {
    this.clients = clients;
  };

  clearSelectedClient = () => {
    this.selectedClient = undefined;
  };

  resetFilters = () => {
    this.nameFilter = '';
    this.pagingParams = new PagingParams();
    this.isActiveFilter = true;
  };

  searchClientsByName = async (searchText: string) => {
    try {
      let params = new URLSearchParams();
      params.append("name", searchText);
      params.append("isActive", "true");
      params.append("pageSize", "100");

      const response = await agent.Clients.list(params);
      
      runInAction(() => {
        this.clients = response.data;
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
}
