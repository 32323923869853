import React, { useEffect, useState, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  SelectChangeEvent,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  ListItemText,
  Divider,
} from "@mui/material";
import SearchBar from "../../../../app/common/form/SearchBar.tsx";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { debounce } from 'lodash';
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { OrderStatus, getOrderStatusLabel } from "../../../../app/models/order.ts";

const OrderFilters: React.FC = () => {
  const { orderStore, clientStore, appUserStore } = useStore();
  const {
    orderNumberFilter,
    isApprovedFilter,
    loadingFilters,
    clients,
    clientFilter,
    userFilter,
    orderStatusFilter,
    loadActiveClients,
    setOrderNumberFilter,
    setIsApprovedFilter,
    setPagingParams,
    loadOrders,
    setClientFilter,
    setUserFilter,
    setClientNameFilter,
    setOrderStatusFilter,
  } = orderStore;

  const [clientSearchText, setClientSearchText] = useState("");
  const [userSearchText, setUserSearchText] = useState("");
  const [clientMenuOpen, setClientMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [filteredClients, setFilteredClients] = useState(clients || []);

  const loadFilteredOrders = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: orderStore.pagingParams.pageSize,
    });
    loadOrders();
  };

  const handleOrderNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderNumberFilter(event.target.value);
    loadFilteredOrders();
  };

  const handleIsApprovedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setIsApprovedFilter(isChecked ? false : true);
    loadFilteredOrders();
  };

  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setClientFilter(value === "" ? "" : value);
    loadFilteredOrders();
  };

  const handleUserChange = (event: SelectChangeEvent<string>) => {
    setUserFilter(event.target.value as string);
    loadFilteredOrders();
  };

  const handleOrderStatusChange = (event: SelectChangeEvent<string>) => {
    setOrderStatusFilter(event.target.value as string);
    loadFilteredOrders();
  };

  useEffect(() => {
    appUserStore.loadAppUsers();
    loadActiveClients();
  }, [appUserStore, loadActiveClients]);

  const debouncedClientSearch = useCallback(
    (searchText: string) => {
      const debouncedFn = debounce(async (text: string) => {
        if (!text.trim()) {
          await loadActiveClients();
          setFilteredClients(clients || []);
        } else {
          const searchResults = await clientStore.searchClientsByName(text);
          setFilteredClients(searchResults || []);
        }
      }, 300);
      debouncedFn(searchText);
    },
    [loadActiveClients, clientStore, clients]
  );

  useEffect(() => {
    setFilteredClients(clients || []);
  }, [clients]);

  const handleClientSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setClientSearchText(searchText);
    debouncedClientSearch(searchText);
  };

  const handleUserSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setUserSearchText(searchText);
    appUserStore.setNameFilter(searchText);
    appUserStore.loadAppUsers();
  };

  if (loadingFilters) return <CircularProgress />;

  return (
    <div>
      <div className="order-filters">
        <SearchBar
          label="Search by order number"
          value={orderNumberFilter}
          onChange={handleOrderNumberChange}
          className="search-bar"
        />
        <FormControlLabel
          control={
            <Switch
              checked={isApprovedFilter === false}
              onChange={handleIsApprovedChange}
            />
          }
          label="Approved"
        />
        
        <FormControl>
          <InputLabel>Order Status</InputLabel>
          <Select
            value={orderStatusFilter}
            onChange={handleOrderStatusChange}
            label="Order Status"
            style={{ width: "200px" }}
          >
            <MenuItem value="">
              <ListItemText primary="All Statuses" />
            </MenuItem>
            {Object.values(OrderStatus)
              .filter(status => typeof status === 'number')
              .map((status) => (
                <MenuItem key={status} value={status.toString()}>
                  {getOrderStatusLabel(status as OrderStatus)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Client</InputLabel>
          <Select
            value={clientFilter || ""}
            onChange={handleClientChange}
            label="Client"
            style={{ width: "200px" }}
            open={clientMenuOpen}
            onOpen={() => setClientMenuOpen(true)}
            onClose={() => setClientMenuOpen(false)}
            MenuProps={{
              PaperProps: { style: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <ListItemText primary="All Clients" />
            </MenuItem>
            <Box sx={{ p: 1, position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 1 }}>
              <TextField
                size="small"
                fullWidth
                value={clientSearchText}
                onChange={handleClientSearch}
                placeholder="Search clients..."
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: clientSearchText && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setClientSearchText('');
                          setClientNameFilter('');
                          loadActiveClients();
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            {filteredClients.map((client) => (
              <MenuItem key={client.id} value={client.id.toString()}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>User</InputLabel>
          <Select
            value={userFilter}
            onChange={handleUserChange}
            label="User"
            style={{ width: "200px" }}
            open={userMenuOpen}
            onOpen={() => setUserMenuOpen(true)}
            onClose={() => setUserMenuOpen(false)}
            MenuProps={{
              PaperProps: { style: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <ListItemText primary="All Users" />
            </MenuItem>
            <Box 
              sx={{ 
                p: 1, 
                position: 'sticky', 
                top: 0, 
                bgcolor: 'background.paper', 
                zIndex: 1 
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <TextField
                size="small"
                fullWidth
                value={userSearchText}
                onChange={handleUserSearch}
                placeholder="Search users..."
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: userSearchText && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setUserSearchText('');
                          appUserStore.setNameFilter('');
                          appUserStore.loadAppUsers();
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            {appUserStore.appUsers.map((user) => (
              <MenuItem 
                key={user.userName} 
                value={user.userName}
                onClick={() => {
                  setUserFilter(user.userName);
                  setUserMenuOpen(false);
                }}
              >
                {user.userName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default observer(OrderFilters);
