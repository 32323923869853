import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Pagination,
  Box,
  CircularProgress,
  Button,
  Alert,
  Snackbar,
  TextField,
  IconButton,
  Grid,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link } from 'react-router-dom';
import ShipmentIcon from "@mui/icons-material/LocalShipping";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { OrderStatus, getOrderStatusLabel, getStatusChipColor } from "../../../../app/models/order.ts";
import agent from "../../../../app/api/agent.ts";
import { debounce } from 'lodash';
import ClearIcon from "@mui/icons-material/Clear";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import SearchIcon from "@mui/icons-material/Search";

const ReturnManagement: React.FC = () => {
  const { orderStore, appUserStore } = useStore();
  const {
    setPagingParams,
    loadOrdersForReturns,
    pagination,
    orders,
    pagingParams,
    setOrders,
    clientFilter,
    orderNumberFilter,
    setClientFilter,
    setOrderNumberFilter,
    clients,
    loadActiveClients,
    userFilter,
    setUserFilter,
    setClientNameFilter,
    orderStatusFilter,
    setOrderStatusFilter,
  } = orderStore;
  const { setNameFilter, loadAppUsers } = appUserStore;

  const [localClientFilter, setLocalClientFilter] = useState(clientFilter);
  const [localOrderNumberFilter, setLocalOrderNumberFilter] = useState(orderNumberFilter);
  const [localUserFilter, setLocalUserFilter] = useState(userFilter);
  const [localOrderStatusFilter, setLocalOrderStatusFilter] = useState(orderStatusFilter);
  const [isFiltering, setIsFiltering] = useState(false);

  const navigateToHome = useCallback((pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    
    // Reset local filter states when changing page
    setLocalClientFilter(clientFilter);
    setLocalOrderNumberFilter(orderNumberFilter);
    setLocalUserFilter(userFilter);
    setLocalOrderStatusFilter(orderStatusFilter);

    loadOrdersForReturns();
  }, [
    setPagingParams, 
    pagingParams.pageSize, 
    clientFilter, 
    orderNumberFilter, 
    userFilter, 
    orderStatusFilter, 
    loadOrdersForReturns
  ]);

  const handlePageChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number) => {
      navigateToHome(value);
    },
    [navigateToHome]
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isShipping, setIsShipping] = useState<{ [key: number]: boolean }>({});

  const handleShipOrder = async (orderId: number) => {
    setIsShipping((prev) => ({ ...prev, [orderId]: true }));
    try {
      const shipmentDate = new Date();
      await agent.Orders.updateOrderStatus(orderId, OrderStatus.Shipped);
      await agent.Orders.updateShipmentDate(orderId, shipmentDate);

      setOrders(
        orders.map((order) =>
          order.id === orderId
            ? {
                ...order,
                status: OrderStatus.Shipped,
                shipmentDate: shipmentDate,
              }
            : order
        )
      );
      setSuccessMessage("Order shipped successfully");
    } catch (error) {
      console.error("Error shipping order:", error);
      setErrorMessage("Failed to ship order");
    } finally {
      setIsShipping((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  useEffect(() => {
    loadOrdersForReturns();
  }, [loadOrdersForReturns]);

  const applyFilters = useCallback(() => {
    const filterAction = debounce(() => {
      setClientFilter(localClientFilter);
      setOrderNumberFilter(localOrderNumberFilter);
      setUserFilter(localUserFilter);
      setOrderStatusFilter(localOrderStatusFilter);
      
      setPagingParams({ 
        pageNumber: 1, 
        pageSize: pagingParams.pageSize 
      });

      loadOrdersForReturns();
      setIsFiltering(false);
    }, 500);

    filterAction();
  }, [
    localClientFilter, 
    localOrderNumberFilter, 
    localUserFilter, 
    localOrderStatusFilter,
    setClientFilter,
    setOrderNumberFilter,
    setUserFilter,
    setOrderStatusFilter,
    setPagingParams,
    pagingParams.pageSize,
    loadOrdersForReturns
  ]);

  useEffect(() => {
    setIsFiltering(true);
    applyFilters();
  }, [
    localClientFilter, 
    localOrderNumberFilter, 
    localUserFilter, 
    localOrderStatusFilter,
    applyFilters
  ]);

  // Add a useEffect to reset filters when component unmounts
  useEffect(() => {
    return () => {
      // Reset filters on unmount
      setClientFilter('');
      setUserFilter('');
      setOrderNumberFilter('');
      setOrderStatusFilter('');
      
      // Reset local states
      setLocalClientFilter('');
      setLocalUserFilter('');
      setLocalOrderNumberFilter('');
      setLocalOrderStatusFilter('');
    };
  }, [
    setClientFilter, 
    setUserFilter, 
    setOrderNumberFilter, 
    setOrderStatusFilter
  ]);

  const [clientSearchText, setClientSearchText] = useState("");
  const [userSearchText, setUserSearchText] = useState("");

  useEffect(() => {
    return () => {
      setClientFilter('');
      setUserFilter('');
      setOrderNumberFilter('');
      setLocalClientFilter('');
      setLocalOrderNumberFilter('');
      setLocalUserFilter('');
      setClientSearchText('');
      setUserSearchText('');
      setNameFilter('');
    };
  }, [
    setClientFilter, 
    setUserFilter, 
    setOrderNumberFilter, 
    setNameFilter,
  ]);

  const debouncedClientSearch = useCallback((searchText: string) => {
    debounce((text: string) => {
      setClientNameFilter(text);
      loadActiveClients();
    }, 300)(searchText);
  }, [setClientNameFilter, loadActiveClients]);

  const handleClientSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchText = event.target.value;
      setClientSearchText(searchText);
      debouncedClientSearch(searchText);
    },
    [debouncedClientSearch, setClientSearchText]
  );

  const handleUserSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchText = event.target.value;
      setUserSearchText(searchText);
      setNameFilter(searchText);
      loadAppUsers();
    },
    [setUserSearchText, setNameFilter, loadAppUsers]
  );

  const clientFilterSection = (
    <FormControl fullWidth size="small">
      <InputLabel>Client</InputLabel>
      <Select
        value={localClientFilter}
        onChange={(e) => setLocalClientFilter(e.target.value)}
        label="Client"
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 400 },
          },
        }}
      >
        <MenuItem value="">
          <ListItemText primary="All Clients" />
        </MenuItem>
        <Box sx={{ p: 1, position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 1 }}>
          <TextField
            size="small"
            fullWidth
            value={clientSearchText}
            onChange={handleClientSearch}
            placeholder="Search clients..."
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: clientSearchText && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setClientSearchText('');
                      setClientNameFilter('');
                      loadActiveClients();
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'background.paper',
              },
            }}
          />
        </Box>
        <Divider />
        {clients.length === 0 ? (
          <MenuItem disabled>
            <ListItemText primary="No clients found" sx={{ textAlign: 'center', color: 'text.secondary' }} />
          </MenuItem>
        ) : (
          clients.map((client) => (
            <MenuItem key={client.id} value={client.id}>
              {client.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );

  const userFilterSection = (
    <FormControl fullWidth size="small">
      <InputLabel>User</InputLabel>
      <Select
        value={localUserFilter || ''}
        onChange={(e) => setLocalUserFilter(e.target.value as string)}
        label="User"
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 400 },
          },
        }}
      >
        <MenuItem value="">
          <ListItemText primary="All Users" />
        </MenuItem>
        <Box sx={{ p: 1, position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 1 }}>
          <TextField
            size="small"
            fullWidth
            value={userSearchText}
            onChange={handleUserSearch}
            placeholder="Search users..."
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: userSearchText && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setUserSearchText('');
                      setNameFilter('');
                      loadAppUsers();
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'background.paper',
              },
            }}
          />
        </Box>
        <Divider />
        {appUserStore.appUsers.length === 0 ? (
          <MenuItem disabled>
            <ListItemText primary="No users found" sx={{ textAlign: 'center', color: 'text.secondary' }} />
          </MenuItem>
        ) : (
          appUserStore.appUsers.map((user) => (
            <MenuItem key={user.userName} value={user.userName}>
              {user.userName}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );

  const orderStatusFilterSection = (
    <FormControl fullWidth size="small">
      <InputLabel>Return Status</InputLabel>
      <Select
        value={localOrderStatusFilter || ''}
        onChange={(e) => setLocalOrderStatusFilter(e.target.value as string)}
        label="Return Status"
      >
        <MenuItem value="">
          <ListItemText primary="All Statuses" />
        </MenuItem>
        <MenuItem value={OrderStatus.ReturnInProgress.toString()}>
          <Chip 
            label={getOrderStatusLabel(OrderStatus.ReturnInProgress)} 
            color={getStatusChipColor(OrderStatus.ReturnInProgress)} 
            size="small" 
          />
        </MenuItem>
        <MenuItem value={OrderStatus.ReturnCompleted.toString()}>
          <Chip 
            label={getOrderStatusLabel(OrderStatus.ReturnCompleted)} 
            color={getStatusChipColor(OrderStatus.ReturnCompleted)} 
            size="small" 
          />
        </MenuItem>
      </Select>
    </FormControl>
  );

  useEffect(() => {
    // console.log('Orders updated:', orders);
  }, [orders]);

  return (
    <Box sx={{ p: 3, maxWidth: '1600px', margin: '0 auto' }}>
      {/* Header Section */}
      
      {/* Filters Paper */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 3, 
          mb: 4,
          borderRadius: 2,
          bgcolor: 'background.paper',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: 4
          }
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            {clientFilterSection}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {userFilterSection}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              size="small"
              label="Order Number"
              value={localOrderNumberFilter}
              onChange={(e) => setLocalOrderNumberFilter(e.target.value)}
              InputProps={{
                endAdornment: localOrderNumberFilter && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setLocalOrderNumberFilter('')}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {orderStatusFilterSection}
          </Grid>
          {isFiltering && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <CircularProgress size={20} sx={{ mr: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  Applying filters...
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Table Paper */}
      <Paper 
        elevation={3} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: 6
          }
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow sx={{ 
                bgcolor: (theme) => theme.palette.primary.main,
                '& th': {
                  padding: 2
                }
              }}>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Order Number
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Order Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Client
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Sales Rep
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Sub Total
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Items
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Shipment Date
                  </Typography>
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} sx={{ textAlign: 'center', py: 4 }}>
                    <Typography variant="h6" color="text.secondary">
                      No returns found
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      Try adjusting your filters or check back later
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                orders.map((order) => (
                  <TableRow
                    key={order.id}
                    sx={{
                      '&:hover': { 
                        bgcolor: 'action.hover',
                        transform: 'translateY(-1px)',
                        transition: 'all 0.2s ease'
                      },
                      '& td': {
                        padding: 2
                      }
                    }}
                  >
                    <TableCell>
                      <Typography variant="body2" sx={{ fontFamily: 'monospace', fontWeight: 'medium' }}>
                        {order.orderNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {formatDate(order.orderDate)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        {order.client.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {order.userName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="primary.main" sx={{ fontWeight: 'medium' }}>
                        ${order.subTotal}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {order.itemsCount}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={getOrderStatusLabel(order.status)}
                        color={getStatusChipColor(order.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {order.shipmentDate ? formatDate(order.shipmentDate) : "Not Available"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {order.status === OrderStatus.WaitingToShip && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={isShipping[order.id] ? 
                            <CircularProgress size={20} color="inherit" /> : 
                            <ShipmentIcon />
                          }
                          onClick={() => handleShipOrder(order.id)}
                          disabled={isShipping[order.id]}
                          sx={{
                            minWidth: '120px',
                            borderRadius: 1,
                            textTransform: 'none',
                            boxShadow: 2,
                            '&:hover': {
                              transform: 'translateY(-1px)',
                              boxShadow: 4
                            }
                          }}
                        >
                          {isShipping[order.id] ? 'Shipping...' : 'Ship Order'}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        component={Link}
                        to={`/admin/warehouse/view-return/${order.id}`}
                        startIcon={<AssignmentReturnIcon />}
                        size="small"
                        variant="contained"
                        color="secondary"
                        sx={{ 
                          minWidth: '120px',
                          borderRadius: 1,
                          textTransform: 'none',
                          boxShadow: 2,
                          '&:hover': {
                            transform: 'translateY(-1px)',
                            boxShadow: 4
                          }
                        }}
                      >
                        View Return
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Pagination */}
      <Box sx={{ 
        mt: 3, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="body2" color="text.secondary">
          {pagination && `Showing ${orders.length} of ${pagination.totalItems} returns`}
        </Typography>
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
          sx={{
            '& .MuiPaginationItem-root': {
              borderRadius: 1
            }
          }}
        />
      </Box>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorMessage(null)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessMessage(null)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default observer(ReturnManagement);