import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import SearchBar from "../../../../app/common/form/SearchBar.tsx";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { OrderStatus, getOrderStatusLabel } from "../../../../app/models/order.ts";

const MyOrderFilters: React.FC = () => {
  const { orderStore } = useStore();
  const {
    orderNumberFilter,
    isApprovedFilter,
    loadingFilters,
    clients,
    clientFilter,
    orderStatusFilter,
    loadActiveClients,
    setOrderNumberFilter,
    setIsApprovedFilter,
    setPagingParams,
    setClientFilter,
    setOrderStatusFilter,
    loadMyOrders,
  } = orderStore;

  const loadFilteredOrders = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: orderStore.pagingParams.pageSize,
    });
    loadMyOrders();
  };

  const handleOrderNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderNumberFilter(event.target.value);
    loadFilteredOrders();
  };

  const handleIsApprovedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setIsApprovedFilter(isChecked ? false : true);
    loadFilteredOrders();
  };

  const handleClientChange = (event: SelectChangeEvent<string>) => {
    setClientFilter(event.target.value as string);
    loadFilteredOrders();
  };

  const handleOrderStatusChange = (event: SelectChangeEvent<string>) => {
    setOrderStatusFilter(event.target.value as string);
    loadFilteredOrders();
  };

  useEffect(() => {
    loadActiveClients();
  }, [loadActiveClients]);

  if (loadingFilters) return <CircularProgress />;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center', mb: 2 }}>
      <SearchBar
        label="Search by order number"
        value={orderNumberFilter}
        onChange={handleOrderNumberChange}
        sx={{ minWidth: 200 }}
      />
      
      <FormControlLabel
        control={
          <Switch
            checked={isApprovedFilter === false}
            onChange={handleIsApprovedChange}
          />
        }
        label="Approved"
      />

      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Client</InputLabel>
        <Select
          value={clientFilter}
          onChange={handleClientChange}
          label="Client"
        >
          <MenuItem value="">
            <em>All Clients</em>
          </MenuItem>
          {clients?.map((client) => (
            <MenuItem key={client.id} value={client.id}>
              {client.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Order Status</InputLabel>
        <Select
          value={orderStatusFilter || ''}
          onChange={handleOrderStatusChange}
          label="Order Status"
        >
          <MenuItem value="">
            <em>All Statuses</em>
          </MenuItem>
          {Object.values(OrderStatus)
            .filter(status => typeof status === 'number')
            .map((status) => (
              <MenuItem key={status} value={status.toString()}>
                {getOrderStatusLabel(status as OrderStatus)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default observer(MyOrderFilters);
