import React, { useEffect, useState, useCallback } from "react";
import { 
  Box, 
  Typography, 
  TextField, 
  Paper, 
  Button, 
  CircularProgress,
  InputAdornment,
  Grid,
  Chip,
  Stack
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import ClearIcon from '@mui/icons-material/Clear';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store.ts";
import { Client } from "../../../app/models/client";
import { debounce } from 'lodash';

// Phone number formatting utility
const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return 'No phone number';
  
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  } else if (cleaned.length === 7) {
    return `${cleaned.slice(0,3)}-${cleaned.slice(3)}`;
  }
  
  return phoneNumber;
};

const SelectClientForm: React.FC = () => {
  const { clientStore, commonStore } = useStore();
  const [searchFields, setSearchFields] = useState({
    name: '',
    code: '',
    phone: '',
    address: ''
  });
  const [isSearching, setIsSearching] = useState(false);

  const debouncedSearch = useCallback((fields: typeof searchFields) => {
    debounce(() => {
      setIsSearching(true);
      clientStore.loadClients({
        name: fields.name,
        code: fields.code,
        phone: fields.phone,
        address: fields.address
      }).finally(() => setIsSearching(false));
    }, 500)();
  }, [clientStore, setIsSearching]);

  const handleSearchChange = (field: keyof typeof searchFields) => 
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFields = {
        ...searchFields,
        [field]: event.target.value
      };
      setSearchFields(newFields);
      debouncedSearch(newFields);
    };

  const handleClearSearch = useCallback(() => {
    setSearchFields({
      name: '',
      code: '',
      phone: '',
      address: ''
    });
    clientStore.loadClients({});
  }, [clientStore]);

  const handleSubmit = useCallback((client: Client) => {
    commonStore?.setSelectedClient(client);
    commonStore?.setOrderMode(true);
  }, [commonStore]);

  const handleClientSelect = useCallback((client: Client) => {
    handleSubmit(client);
  }, [handleSubmit]);

  // Add useEffect for cleanup
  useEffect(() => {
    return () => {
      handleClearSearch();
      clientStore.resetFilters();
    };
  }, [handleClearSearch, clientStore]);

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', p: 3 }}>
      {/* Search Section */}
      <Paper elevation={0} sx={{ p: 3, mb: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
        <Typography variant="h5" sx={{ mb: 3 }}>Search Clients</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Name"
              value={searchFields.name}
              onChange={handleSearchChange('name')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Code"
              value={searchFields.code}
              onChange={handleSearchChange('code')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Phone"
              value={searchFields.phone}
              onChange={handleSearchChange('phone')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Address"
              value={searchFields.address}
              onChange={handleSearchChange('address')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        
        {/* Search Stats */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            {clientStore.clients.length > 0 
              ? `Found ${clientStore.clients.length} clients` 
              : 'No clients found'}
          </Typography>
          {(searchFields.name || searchFields.code || searchFields.phone || searchFields.address) && (
            <Button 
              startIcon={<ClearIcon />} 
              onClick={handleClearSearch}
              size="small"
            >
              Clear Search
            </Button>
          )}
        </Box>
      </Paper>

      {/* Results Section */}
      <Paper elevation={0} sx={{ p: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
        {isSearching ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : clientStore.clients.length > 0 ? (
          <Grid container spacing={2}>
            {clientStore.clients.map((client) => (
              <Grid item xs={12} sm={6} md={4} key={client.id}>
                <Paper 
                  elevation={0}
                  sx={{ 
                    p: 2, 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2,
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 1
                    }
                  }}
                  onClick={() => handleClientSelect(client)}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, gap: 1 }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        maxHeight: '2.4em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical'
                      }}
                    >
                      {client.name}
                    </Typography>
                    <Chip 
                      size="small"
                      label={client.isActive ? 'Active' : 'Inactive'}
                      color={client.isActive ? 'success' : 'default'}
                      sx={{ flexShrink: 0 }}
                    />
                  </Box>
                  <Stack spacing={1}>
                    {client.code && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BusinessIcon fontSize="small" color="action" />
                        <Typography variant="body2">{client.code}</Typography>
                      </Box>
                    )}
                    {client.phoneNumber && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <PhoneIcon fontSize="small" color="action" />
                        <Typography variant="body2">{formatPhoneNumber(client.phoneNumber)}</Typography>
                      </Box>
                    )}
                    {client.address && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LocationOnIcon fontSize="small" color="action" />
                        <Typography variant="body2" noWrap>{client.address}</Typography>
                      </Box>
                    )}
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit(client)}
                      sx={{ mt: 2 }}
                    >
                      Start Order
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <SearchOffIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No clients found
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Try adjusting your search criteria
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default observer(SelectClientForm);
