import { Client } from "./client";
import { OrderItem } from "./orderItem";
import { AppUser } from "./user";

// Enum with integer values for backend compatibility
export enum OrderStatus {
  WaitingForApproval = 0, // 0 means "Waiting for approval"
  Revoked = 1, // 1 means "Revoked"
  OrderReceived = 2, // 2 means "Order received"
  Preparing = 3, // 3 means "Preparing"
  OnHold = 4, // 4 means "On hold"
  Shipped = 5, // 5 means "Shipped"
  Delivered = 6, // 6 means "Delivered"
  Delayed = 7, // 7 means "Delayed"
  Approved = 8, // 8 means "Approved",
  WaitingToShip=9,
  OutOfStock = 10,
  ReturnInProgress=11,
  ReturnCompleted=12
}

// Add the status label function
export const getOrderStatusLabel = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.WaitingForApproval:
      return "Waiting for Approval";
    case OrderStatus.Revoked:
      return "Revoked";
    case OrderStatus.OrderReceived:
      return "Order Received";
    case OrderStatus.Preparing:
      return "Preparing";
    case OrderStatus.OnHold:
      return "On Hold";
    case OrderStatus.Shipped:
      return "Shipped";
    case OrderStatus.Delivered:
      return "Delivered";
    case OrderStatus.Delayed:
      return "Delayed";
    case OrderStatus.Approved:
      return "Approved";
    case OrderStatus.WaitingToShip:
      return "Waiting to Ship";
    case OrderStatus.OutOfStock:
      return "Out of Stock";
    case OrderStatus.ReturnInProgress:
      return "Return in Progress";
    case OrderStatus.ReturnCompleted:
      return "Return Completed";
    default:
      return "Unknown";
  }
};

// Add type for MUI color options
export type StatusChipColor = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";

// Add the status chip color function
export const getStatusChipColor = (status: OrderStatus): StatusChipColor => {
  switch (status) {
    case OrderStatus.WaitingForApproval:
      return "warning";
    case OrderStatus.Revoked:
      return "error";
    case OrderStatus.OrderReceived:
      return "info";
    case OrderStatus.Preparing:
      return "primary";
    case OrderStatus.OnHold:
      return "secondary";
    case OrderStatus.Shipped:
      return "success";
    case OrderStatus.Delivered:
      return "success";
    case OrderStatus.Delayed:
      return "error";
    case OrderStatus.Approved:
      return "success";
    case OrderStatus.WaitingToShip:
      return "warning";
    case OrderStatus.OutOfStock:
      return "error";
    case OrderStatus.ReturnInProgress:
      return "warning";
    case OrderStatus.ReturnCompleted:
      return "success";
    default:
      return "default";
  }
};

export interface IOrder {
  id?: number;
  orderNumber: string;
  orderDate: Date;
  clientId: number;
  client: Client;
  userName: string;
  user?: AppUser;
  userId?: string;
  subTotal: number;
  itemsCount: number;
  orderItems: OrderItem[];
  notes?: string;
  status: number;
  shipmentDate?: Date;
}

// Class representing an order
export class Order implements IOrder {
  id?: number;
  orderNumber: string;
  orderDate: Date;
  clientId: number;
  client: Client;
  userName: string;
  user?: AppUser;
  userId?: string;
  subTotal: number;
  itemsCount: number;
  orderItems: OrderItem[];
  notes?: string;
  status: number;
  shipmentDate?: Date;
  constructor(init?: IOrder) {
    Object.assign(this, init);
  }
}