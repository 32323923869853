import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Chip,
  Button,
  Breadcrumbs,
  Link,
  TextField,
  Checkbox,
  Tooltip,
  IconButton,
  Snackbar,
  Alert as MuiAlert,
  LinearProgress
} from '@mui/material';
import { useStore } from '../../../../app/stores/store.ts';
import { observer } from "mobx-react-lite";
import agent from '../../../../app/api/agent.ts';
import { OrderDetail } from '../../../../app/models/orderDetail.ts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InventoryIcon from '@mui/icons-material/Inventory';
import InfoIcon from '@mui/icons-material/Info';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import UpdateIcon from '@mui/icons-material/Update';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled } from '@mui/material/styles';
import { 
  Card, 
  CardContent, 
  Stepper, 
  Step, 
  StepLabel
} from '@mui/material';
import { OrderStatus } from "../../../../app/models/order.ts";
import { ReturnType } from "../../../../app/models/return.ts";
import { alpha } from '@mui/material/styles';

interface ReturnLot {
  lotId: number;
  lotNumber: string;
  quantity: number;
  maxQuantity: number;
  selected: boolean;
}

interface ReturnItem {
  orderItemId: number;
  quantity: number;
  lots: ReturnLot[];
  selected: boolean;
}

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
  marginBottom: theme.spacing(3)
}));

const ReturnHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
  marginBottom: theme.spacing(3)
}));

const ReturnTypeCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  '&.selected': {
    borderColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  }
}));

const ReturnTypeIcon = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
}));

const returnTypeIcons = {
  [ReturnType.FULL]: <AssignmentReturnIcon />,
  [ReturnType.PARTIAL]: <FilterNoneIcon />,
  [ReturnType.DAMAGED]: <BrokenImageIcon />,
  [ReturnType.WRONG_ITEM]: <SwapHorizIcon />,
  [ReturnType.QUALITY_ISSUE]: <ReportProblemIcon />,
  [ReturnType.EXPIRED]: <UpdateIcon />,
  [ReturnType.CUSTOMER_DISSATISFACTION]: <SentimentDissatisfiedIcon />,
  [ReturnType.SHIPPING_ERROR]: <LocalShippingIcon />,
  [ReturnType.OTHER]: <MoreHorizIcon />
};

const returnTypeDescriptions = {
  [ReturnType.FULL]: 'Return the entire order',
  [ReturnType.PARTIAL]: 'Return a portion of the order',
  [ReturnType.DAMAGED]: 'Return damaged products',
  [ReturnType.WRONG_ITEM]: 'Return wrong items received',
  [ReturnType.QUALITY_ISSUE]: 'Return products with quality issues',
  [ReturnType.EXPIRED]: 'Return expired products',
  [ReturnType.CUSTOMER_DISSATISFACTION]: 'Return products due to customer dissatisfaction',
  [ReturnType.SHIPPING_ERROR]: 'Return products due to shipping errors',
  [ReturnType.OTHER]: 'Return products for other reasons'
};

const CreateReturnScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { orderStore } = useStore();
  const { loadOrderItems, loadingInitial, loadOrderById } = orderStore;
  const navigate = useNavigate();

  const [state, setState] = useState({
    orderNumber: null as string | null,
    orderDetails: null as OrderDetail[] | null,
  });

  const [returnDetails, setReturnDetails] = useState({
    reason: '',
    notes: '',
    returnType: ReturnType.FULL,
  });

  const [returnItems, setReturnItems] = useState<ReturnItem[]>([]);

  const steps = ['Order Details', 'Select Items', 'Review & Submit'];
  const activeStep = 1; // You can make this dynamic based on the return process

  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });
  
  const {
    updateOrderStatus,
  } = orderStore;

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!id) return;

      try {
        const [order, orderItemsWithLots] = await Promise.all([
          loadOrderById(Number(id)),
          agent.Orders.getOrderItemsWithLots(Number(id))
        ]);

        setState(prev => ({
          ...prev,
          orderNumber: order?.orderNumber || null,
          orderDetails: orderItemsWithLots
        }));
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
    loadOrderItems(Number(id));
  }, [id, loadOrderItems, loadOrderById]);

  useEffect(() => {
    if (state.orderDetails?.items) {
      setReturnItems(state.orderDetails.items.map(item => ({
        orderItemId: item.orderItemId,
        quantity: 0,
        lots: item.lots.map(lot => ({
          lotId: lot.lotId,
          lotNumber: lot.lotNumber,
          quantity: 0,
          maxQuantity: lot.caseQuantity + lot.unitQuantity,
          selected: false
        })),
        selected: false
      })));
    }
  }, [state.orderDetails]);

  const handleItemSelection = (orderItemId: number) => {
    setReturnItems(prev => prev.map(item => 
      item.orderItemId === orderItemId 
        ? { ...item, selected: !item.selected }
        : item
    ));
  };

  const handleLotSelection = (orderItemId: number, lotId: number) => {
    setReturnItems(prev => prev.map(item => 
      item.orderItemId === orderItemId 
        ? {
            ...item,
            lots: item.lots.map(lot =>
              lot.lotId === lotId 
                ? { ...lot, selected: !lot.selected }
                : lot
            )
          }
        : item
    ));
  };

  const handleLotQuantityChange = (orderItemId: number, lotId: number, newQuantity: number) => {
    setReturnItems(prev => prev.map(item => {
      if (item.orderItemId === orderItemId) {
        const updatedLots = item.lots.map(lot =>
          lot.lotId === lotId
            ? { ...lot, quantity: Math.min(newQuantity, lot.maxQuantity) }
            : lot
        );
        const totalQuantity = updatedLots.reduce((sum, lot) => sum + lot.quantity, 0);
        return {
          ...item,
          quantity: totalQuantity,
          lots: updatedLots
        };
      }
      return item;
    }));
  };

  const handleSubmitReturn = async () => {
    try {
      const selectedItems = returnItems
        .filter(item => item.selected && item.lots.some(lot => lot.selected && lot.quantity > 0))
        .map(item => {
          const originalItem = state.orderDetails?.items.find(
            orderItem => orderItem.orderItemId === item.orderItemId
          );

          return {
            orderItemId: item.orderItemId,
            quantity: item.lots.reduce((sum, lot) => sum + (lot.selected ? lot.quantity : 0), 0),
            isCaseQuantity: originalItem?.byCase ?? false,
            lots: item.lots
              .filter(lot => lot.selected && lot.quantity > 0)
              .map(lot => ({
                lotId: lot.lotId,
                lotNumber: lot.lotNumber,
                quantity: lot.quantity
              }))
          };
        });

      const returnRequest = {
        orderId: Number(id),
        reason: returnDetails.reason || "No reason provided",
        notes: returnDetails.notes,
        returnType: returnDetails.returnType.toUpperCase(),
        items: selectedItems
      };

      await agent.Returns.create(returnRequest);
      await updateOrderStatus(Number(id), OrderStatus.ReturnInProgress);
      setNotification({
        open: true,
        message: 'Return created successfully!',
        severity: 'success'
      });
      setTimeout(() => {
        navigate('/admin/warehouse/ship-management');
      }, 2000);
    } catch (error) {
      console.error("Error creating return:", error);
      setNotification({
        open: true,
        message: error.response?.data?.message || 'Failed to create return. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification(prev => ({ ...prev, open: false }));
  };

  const calculateReturnProgress = () => {
    let completedSteps = 0;
    const totalSteps = 4; // Total required steps: returnType, reason, item selection, quantity

    // Check return type
    if (returnDetails.returnType) {
      completedSteps++;
    }

    // Check reason
    if (returnDetails.reason.trim()) {
      completedSteps++;
    }

    // Check if any items are selected
    if (returnItems.some(item => item.selected)) {
      completedSteps++;
    }

    // Check if selected items have quantities
    if (returnItems.some(item => 
      item.selected && 
      item.lots.some(lot => lot.selected && lot.quantity > 0)
    )) {
      completedSteps++;
    }

    // If OTHER type is selected, require specificType
    if (returnDetails.returnType === ReturnType.OTHER) {
      if (!returnDetails.specificType?.trim()) {
        return Math.round((completedSteps / (totalSteps + 1)) * 100);
      }
      completedSteps++;
    }

    return Math.round((completedSteps / (returnDetails.returnType === ReturnType.OTHER ? totalSteps + 1 : totalSteps)) * 100);
  };

  if (loadingInitial) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  
  if (!state.orderDetails?.items?.length) return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">No items found for this order</Typography>
      <Button 
        variant="outlined" 
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/admin/warehouse/ship-management')}
        sx={{ mt: 2 }}
      >
        Back to Ship Management
      </Button>
    </Box>
  );

  return (
    <Box sx={{ p: 3, maxWidth: 1400, margin: '0 auto' }}>
      {/* Enhanced Header */}
      <ReturnHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Create Return
            </Typography>
            <Breadcrumbs sx={{ color: 'inherit' }}>
              <Link 
                color="inherit" 
                href="/admin/warehouse/ship-management"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <AssignmentReturnIcon sx={{ mr: 0.5 }} fontSize="small" />
                Shipment
              </Link>
              <Typography color="inherit">
                Order #{state.orderNumber}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/admin/warehouse/ship-management')}
          >
            Back to Shipment
          </Button>
        </Box>
      </ReturnHeader>

      {/* Progress Stepper */}
      <StyledCard>
        <CardContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </StyledCard>

      {state.orderDetails && (
        <>
          {/* Enhanced Return Details Form */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <ReceiptLongIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Return Details</Typography>
              </Box>

              {/* Return Type Selection */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Select Return Type
                </Typography>
                <Grid container spacing={2}>
                  {Object.values(ReturnType).map((type) => (
                    <Grid item xs={12} sm={6} md={4} key={type}>
                      <ReturnTypeCard
                        className={returnDetails.returnType === type ? 'selected' : ''}
                        onClick={() => setReturnDetails(prev => ({
                          ...prev,
                          returnType: type
                        }))}
                      >
                        <ReturnTypeIcon>
                          {returnTypeIcons[type]}
                        </ReturnTypeIcon>
                        <Typography variant="subtitle2" gutterBottom>
                          {type.replace(/_/g, ' ').toLowerCase()
                            .replace(/\b\w/g, l => l.toUpperCase())}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {returnTypeDescriptions[type]}
                        </Typography>
                      </ReturnTypeCard>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* Return Details Form */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Return Information
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Return Reason"
                      required
                      value={returnDetails.reason}
                      onChange={(e) => setReturnDetails(prev => ({
                        ...prev,
                        reason: e.target.value
                      }))}
                      helperText={`Please provide a detailed reason for the ${returnDetails.returnType.toLowerCase().replace(/_/g, ' ')} return`}
                      multiline
                      rows={2}
                    />
                  </Grid>

                  {returnDetails.returnType === ReturnType.OTHER && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Specify Return Type"
                        required
                        value={returnDetails.specificType || ''}
                        onChange={(e) => setReturnDetails(prev => ({
                          ...prev,
                          specificType: e.target.value
                        }))}
                        helperText="Please specify the return type"
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Additional Notes"
                      value={returnDetails.notes}
                      onChange={(e) => setReturnDetails(prev => ({
                        ...prev,
                        notes: e.target.value
                      }))}
                      helperText="Add any specific details about the return (optional)"
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Return Progress Indicator */}
              <Box sx={{ mt: 4 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={calculateReturnProgress()} 
                  sx={{ 
                    mb: 1,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 4,
                    }
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="caption" color="text.secondary">
                    Return form completion: {calculateReturnProgress()}%
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {returnItems.filter(item => item.selected).length} items selected
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </StyledCard>

          {/* Enhanced Items Table */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <InventoryIcon sx={{ mr: 2, color: 'primary.main' }} />
                  <Typography variant="h6">Return Items</Typography>
                </Box>
                <Tooltip title="Select items and specify quantities for return">
                  <IconButton size="small">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          // Add select all functionality
                          onChange={() => {/* handle select all */}}
                        />
                      </TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell align="right">Total Return Quantity</TableCell>
                      <TableCell>Lot Returns</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.orderDetails.items.map((item, index) => (
                      <TableRow 
                        key={item.orderItemId}
                        sx={{ 
                          '&:hover': { bgcolor: 'action.hover' },
                          bgcolor: returnItems[index]?.selected ? 'action.selected' : 'inherit'
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={returnItems[index]?.selected || false}
                            onChange={() => handleItemSelection(item.orderItemId)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                            <img
                              src={item.productImageUrl}
                              alt={item.productName}
                              style={{ width: 50, height: 50, objectFit: 'cover' }}
                            />
                            <Typography>{item.productName}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{item.productDescription}</TableCell>
                        <TableCell align="right">
                          <Typography variant="h6">
                            {returnItems[index]?.quantity || 0}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            of {item.quantity} {item.byCase ? 'Cases' : 'Units'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {returnItems[index]?.lots.map((lot) => (
                              <Paper
                                key={lot.lotId}
                                elevation={0}
                                sx={{
                                  p: 2,
                                  border: '1px solid',
                                  borderColor: lot.selected ? 'primary.main' : 'divider',
                                  borderRadius: 2,
                                  transition: 'all 0.2s',
                                  '&:hover': {
                                    borderColor: 'primary.main',
                                    bgcolor: 'action.hover'
                                  }
                                }}
                              >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Checkbox
                                      checked={lot.selected}
                                      onChange={() => handleLotSelection(item.orderItemId, lot.lotId)}
                                      disabled={!returnItems[index]?.selected}
                                      size="small"
                                    />
                                    <Typography variant="subtitle2">
                                      Lot: {lot.lotNumber}
                                    </Typography>
                                  </Box>
                                  <Chip 
                                    label={`Max: ${lot.maxQuantity}`}
                                    size="small"
                                    color="info"
                                    variant="outlined"
                                  />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 4 }}>
                                  <TextField
                                    type="number"
                                    size="small"
                                    label="Return Quantity"
                                    value={lot.quantity}
                                    onChange={(e) => handleLotQuantityChange(
                                      item.orderItemId,
                                      lot.lotId,
                                      Math.max(0, parseInt(e.target.value) || 0)
                                    )}
                                    disabled={!lot.selected || !returnItems[index]?.selected}
                                    InputProps={{
                                      inputProps: { 
                                        min: 0, 
                                        max: lot.maxQuantity 
                                      }
                                    }}
                                    sx={{ width: '150px' }}
                                  />
                                </Box>
                              </Paper>
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>

          {/* Enhanced Submit Section */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Return Summary
                  </Typography>
                  <Typography color="text.secondary">
                    {returnItems.filter(item => item.selected).length} items selected
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {returnItems.reduce((sum, item) => 
                      sum + item.lots.filter(lot => lot.selected).length, 0
                    )} lots included
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => {navigate('/admin/warehouse/ship-management')}}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSubmitReturn}
                    disabled={!returnItems.some(item => 
                      item.selected && item.lots.some(lot => lot.selected && lot.quantity > 0)
                    )}
                  >
                    Submit Return
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </StyledCard>
        </>
      )}

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <MuiAlert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default observer(CreateReturnScreen);