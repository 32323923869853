import { saveAs } from 'file-saver';
import agent from '../api/agent.ts';
import { Order, OrderStatus } from '../models/order.ts';
import dayjs from 'dayjs';

export const generateRefundInvoice = async (orderNumber: number, order: Order) => {
  try {
    const returnData = await agent.Returns.details(orderNumber);
    
    if (!returnData || !order) {
      throw new Error('No return data or order found');
    }

    if (order.status !== OrderStatus.ReturnCompleted) {
      throw new Error('Cannot generate refund invoice: Return is not completed');
    }

    let invoiceContent = "";
    
    // Version section (matching IMP file format)
    invoiceContent += `<Version>\n"12001", "1"\n</Version>\n\n`;

    // Return Invoice section
    invoiceContent += `<ReturnInvoice>\n`;
    
    // Client info line (matching IMP format)
    invoiceContent += `"${order.client.name}", , ,, ,, , ,, , , ,\n`;

    // Calculate distinct products (same as IMP file)
    const distinctProductCount = new Set(
      returnData.items.map((item) => item.productCode)
    ).size;

    // Order summary line (matching IMP format)
    const formattedDate = dayjs().format("MM-DD-YY");
    const totalRefundAmount = returnData.items.reduce((sum, item) => {
      const originalItem = order.orderItems.find(oi => oi.product.code === item.productCode);
      return sum + (originalItem ? item.quantity * originalItem.unitPrice : 0);
    }, 0);

    invoiceContent += `"${distinctProductCount}","${orderNumber}", ,"${formattedDate}","0", ,"${totalRefundAmount.toFixed(2)}","0.00"\n`;

    // Process each returned item (similar to IMP format)
    returnData.items.forEach(returnItem => {
      const originalOrderItem = order.orderItems.find(
        oi => oi.id === returnItem.orderItemId
      );

      if (!originalOrderItem) return;

      const itemSubtotal = returnItem.quantity * originalOrderItem.unitPrice;
      
      // Start item line with basic info
      invoiceContent += `"${originalOrderItem.product.code}","${returnItem.quantity.toFixed(4)}","${originalOrderItem.unitPrice.toFixed(4)}","${itemSubtotal.toFixed(2)}"`;

      // Add tax information if present (matching IMP format)
      if (originalOrderItem.taxMappings) {
        originalOrderItem.taxMappings.forEach(mapping => {
          const rate = mapping.overrideRate ?? mapping.tax.rate;
          const taxAmount = (itemSubtotal * rate) / 100;
          invoiceContent += `,"${mapping.tax.code}","${mapping.tax.isIncluded ? "1" : "0"}","${
            mapping.tax.isRefunded ? "1" : "0"
          }","${rate.toFixed(3)}","${taxAmount.toFixed(2)}"`;
        });
      }

      invoiceContent += "\n";
    });

    invoiceContent += `</ReturnInvoice>\n`;

    // Generate file
    const blob = new Blob([invoiceContent], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `return_${orderNumber}_${formattedDate}.txt`);

    return {
      success: true,
      message: 'Return invoice generated successfully'
    };

  } catch (error) {
    console.error('Error generating return invoice:', error);
    throw error;
  }
};

// Add a helper function to check if refund invoice can be generated
export const canGenerateRefundInvoice = (orderStatus: OrderStatus): boolean => {
  return orderStatus === OrderStatus.ReturnCompleted;
};
