import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  TableRow,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Grid,
  Chip,
  Button,
  Breadcrumbs,
  Link,
  Card,
  CardContent,
  styled,
  alpha,
} from '@mui/material';
import { useStore } from '../../../../app/stores/store.ts';
import { observer } from "mobx-react-lite";
import agent from '../../../../app/api/agent.ts';
import { OrderDetail } from '../../../../app/models/orderDetail.ts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link as RouterLink } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
  marginBottom: theme.spacing(3)
}));

const ShipmentHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
  marginBottom: theme.spacing(3)
}));

const ViewShipmentScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { orderStore } = useStore();
  const { loadOrderItems, loadingInitial, loadOrderById } = orderStore;
  const navigate = useNavigate();

  const [state, setState] = useState({
    orderNumber: null as string | null,
    orderDetails: null as OrderDetail[] | null,
  });

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!id) return;

      try {
        const [order, orderItemsWithLots] = await Promise.all([
          loadOrderById(Number(id)),
          agent.Orders.getOrderItemsWithLots(Number(id))
        ]);

        setState(prev => ({
          ...prev,
          orderNumber: order?.orderNumber || null,
          orderDetails: orderItemsWithLots
        }));
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
    loadOrderItems(Number(id));
  }, [id, loadOrderItems, loadOrderById]);

  const getStatusColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'approved':
      case '8':
        return 'success';
      case 'waiting for approval':
      case '0':
        return 'warning';
      case 'shipped':
      case '5':
        return 'info';
      case 'delivered':
      case '6':
        return 'success';
      case 'revoked':
      case '1':
        return 'error';
      case 'preparing':
      case '3':
        return 'info';
      case 'on hold':
      case '4':
        return 'warning';
      case 'delayed':
      case '7':
        return 'error';
      case 'waiting to ship':
      case '9':
        return 'warning';
      case 'order received':
      case '2':
        return 'info';
      default:
        return 'default';
    }
  };

  if (loadingInitial) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  
  if (!state.orderDetails?.items?.length) return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">No items found for this order</Typography>
      <Button 
        variant="outlined" 
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/admin/warehouse/ship-management')}
        sx={{ mt: 2 }}
      >
        Back to Order Management
      </Button>
    </Box>
  );

  return (
    <Box sx={{ p: 3, maxWidth: 1400, margin: '0 auto' }}>
      {/* Header Section */}
      <ShipmentHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              View Shipment
            </Typography>
            <Breadcrumbs sx={{ color: 'inherit' }}>
              <RouterLink 
                to="/admin/warehouse/ship-management"
                style={{ color: 'inherit', display: 'flex', alignItems: 'center', textDecoration: 'none' }}
              >
                <LocalShippingIcon sx={{ mr: 0.5 }} fontSize="small" />
                Ship Management
              </RouterLink>
              <Typography color="inherit">
                Order #{state.orderNumber}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/admin/warehouse/ship-management')}
          >
            Back to Shipments
          </Button>
        </Box>
      </ShipmentHeader>

      {state.orderDetails && (
        <>
          {/* Order Summary Card */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <InventoryIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Order Details</Typography>
                <Chip 
                  label={state.orderDetails.status}
                  color={getStatusColor(state.orderDetails.status)}
                  sx={{ ml: 'auto' }}
                />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ 
                    p: 2, 
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box sx={{ 
                        p: 1.5, 
                        borderRadius: '50%', 
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1)
                      }}>
                        <LocalShippingIcon color="primary" />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Order Number
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {state.orderNumber}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Order Items Table */}
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <InventoryIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="h6">Order Items</Typography>
              </Box>

              <TableContainer component={Paper} sx={{ 
                borderRadius: 2,
                boxShadow: 'none',
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ 
                      bgcolor: 'primary.main',
                    }}>
                      <TableCell width="25%" sx={{ color: 'white' }}>Product</TableCell>
                      <TableCell width="25%" sx={{ color: 'white' }}>Details</TableCell>
                      <TableCell align="right" sx={{ color: 'white' }}>Quantity</TableCell>
                      <TableCell sx={{ color: 'white' }}>Assigned Lots</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.orderDetails.items.map((item) => (
                      <TableRow 
                        key={item.orderItemId}
                        sx={{ '&:hover': { bgcolor: 'action.hover' } }}
                      >
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                            <Paper 
                              elevation={2}
                              sx={{ 
                                width: 100, 
                                height: 100, 
                                overflow: 'hidden',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider'
                              }}
                            >
                              <img
                                src={`${process.env.REACT_APP_API_ROOT}${item.productImageUrl}`}
                                alt={item.productName}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain'
                                }}  
                              />
                            </Paper>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 500, color: 'primary.main' }}>
                                {item.productName}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                                Code: {item.code}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{item.productDescription}</Typography>
                          <Chip 
                            label={item.category}
                            size="small"
                            sx={{ mt: 1, bgcolor: 'grey.100' }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Chip
                            label={`${item.quantity} ${item.byCase ? 'Cases' : 'Units'}`}
                            color="primary"
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ 
                              display: 'flex', 
                              justifyContent: 'space-between', 
                              alignItems: 'center'
                            }}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Assigned Lots ({item.lots.length})
                              </Typography>
                            </Box>
                            {item.lots.length > 0 ? (
                              <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 1,
                                bgcolor: 'grey.50',
                                borderRadius: 1,
                                p: 1
                              }}>
                                {item.lots.map((lot) => (
                                  <Paper
                                    key={`lot-box-${lot.lotId || `${lot.lotNumber}-${lot.warehouseLocation}`}`}
                                    elevation={0}
                                    sx={{
                                      p: 1.5,
                                      border: '1px solid',
                                      borderColor: 'divider',
                                      bgcolor: 'background.paper',
                                      '&:hover': {
                                        bgcolor: 'action.hover',
                                        transition: 'background-color 0.2s'
                                      }
                                    }}
                                  >
                                    <Grid container spacing={2} alignItems="center">
                                      <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          <LocationOnIcon color="primary" fontSize="small" />
                                          <Box>
                                            <Typography variant="subtitle2">
                                              Lot: {lot.lotNumber}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                              Location: {lot.warehouseLocation}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Box sx={{ 
                                          display: 'flex', 
                                          gap: 2,
                                          justifyContent: { xs: 'flex-start', sm: 'flex-end' }
                                        }}>
                                          {lot.caseQuantity > 0 && (
                                            <Chip
                                              label={`${lot.caseQuantity} Cases`}
                                              size="small"
                                              variant="outlined"
                                              sx={{ 
                                                borderColor: 'primary.main',
                                                color: 'primary.main',
                                                bgcolor: 'primary.lighter'
                                              }}
                                            />
                                          )}
                                          {lot.unitQuantity > 0 && (
                                            <Chip
                                              label={`${lot.unitQuantity} Units`}
                                              size="small"
                                              variant="outlined"
                                              sx={{ 
                                                borderColor: 'secondary.main',
                                                color: 'secondary.main',
                                                bgcolor: 'secondary.lighter'
                                              }}
                                            />
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                ))}
                              </Box>
                            ) : (
                              <Box sx={{ 
                                p: 2, 
                                textAlign: 'center', 
                                bgcolor: 'grey.50',
                                borderRadius: 1
                              }}>
                                <Typography variant="body2" color="text.secondary">
                                  No lots assigned
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </>
      )}
    </Box>
  );
};

export default observer(ViewShipmentScreen);