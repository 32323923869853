import * as React from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  styled,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NavLink, useLocation } from "react-router-dom";
import { ADMIN_MENU_ITEMS, USER_MENU_ITEMS } from "../utils/constants.ts";
import { blue, grey } from "@mui/material/colors";
import { useStore } from "../stores/store.ts";

interface MenuItem {
  text: string;
  icon: React.ComponentType;
}

// Add styled components for the drawer
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DRAWER_WIDTH = 240;

interface SideBarProps {
  open: boolean;
  onToggle: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ open, onToggle }) => {
  const location = useLocation();
  const { userStore } = useStore();
  const menuItems: MenuItem[] =
    userStore.user?.role === "Admin" ? ADMIN_MENU_ITEMS : USER_MENU_ITEMS;

  const isPathActive = (to: string) => {
    const path = location.pathname.toLowerCase();
    
    // Special case for home route
    if (to === 'home') {
      return path.startsWith('/admin/home');
    }
    
    // Special case for orders route to avoid matching dashboard-orders
    if (to === 'orders') {
      return path === '/admin/orders' || path.startsWith('/admin/orders/');
    }
    
    // For other routes
    const pathSegments = path.split('/');
    return pathSegments.includes(to);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: open ? DRAWER_WIDTH : 65,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? DRAWER_WIDTH : 65,
          height: '100vh',
          position: 'fixed',
          transition: theme => theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: 'hidden',
          overflowY: 'auto',
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          zIndex: (theme) => theme.zIndex.drawer,
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: '2px',
          },
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={onToggle}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <List>
        {menuItems.map((item) => {
          const to = `${item.text.replace(" ", "-").toLowerCase()}`;
          const Icon = item.icon;

          return (
            <NavLink
              to={to}
              key={item.text}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isPathActive(to)
                    ? blue[500]
                    : "inherit",
                  color: isPathActive(to) ? "#fff" : "inherit",
                  ":hover": {
                    backgroundColor: grey[300],
                    color: "black",
                  },
                }}
              >
                <ListItemIcon 
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: isPathActive(to) ? "#fff" : "inherit"
                  }}
                >
                  <Icon />
                </ListItemIcon>
                <ListItemText 
                  primary={item.text} 
                  sx={{ 
                    opacity: open ? 1 : 0,
                    display: open ? 'block' : 'none'
                  }} 
                />
              </ListItemButton>
            </NavLink>
          );
        })}
      </List>
    </Drawer>
  );
};

export default SideBar;
