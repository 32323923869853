import { makeAutoObservable, runInAction } from "mobx";
import {
  IUser,
  ILoginFormValues,
  IAppUser,
  AppUserFormValues,
} from "../models/user.ts";
import agent from "../api/agent.ts";
import { store } from "./store.ts";
import { Router } from "../router/Router.tsx";

export default class UserStore {
  user: IUser | null = null;
  profileUser: IAppUser;
  editMode = false;
  loadingInitial = true;
  submitting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (creds: ILoginFormValues) => {
    const user = await agent.Account.login(creds);
    store.commonStore.setToken(user.token);
    runInAction(() => {
      this.user = user;
    });
    // Check user role and navigate accordingly
    if (this.user?.role === "Admin") {
      Router.navigate("/admin");
    } else {
      Router.navigate("/user");
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    this.user = null;
    Router.navigate("/");
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      console.log(error);
    }
  };
  getUserProfile = async (userName: string) => {
    try {
      const user = await agent.Account.current();
      const profile = await agent.currentAppUser.details(user.userName);
      runInAction(() => {
        this.profileUser = profile;
      });
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  updateUserProfile = async (values: AppUserFormValues) => {
    try {
      await agent.currentAppUser.edit(values);
      runInAction(() => {
        this.profileUser = { ...this.profileUser, ...values };
      });
    } catch (error) {
      console.error("Failed to update profile", error);
    }
  };
  setEditMode = (value: boolean) => {
    this.editMode = value;
  };
}
