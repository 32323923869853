import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Pagination,
  Button,
  Grid,
  Chip,
  Card,
  CardContent,
  styled,
  alpha,
  Link
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store.ts";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InventoryIcon from '@mui/icons-material/Inventory';
import { 
  OrderStatus, 
  getOrderStatusLabel, 
  getStatusChipColor, 
  StatusChipColor 
} from '../../../app/models/order.ts';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
  marginBottom: theme.spacing(3)
}));

const OrderHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
  marginBottom: theme.spacing(3)
}));

const OrderLookupOrderItems: React.FC = () => {
  const { orderStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = location.state?.searchParams;
  const { orderId } = useParams<{ orderId: string }>();

  const {
    loadOrderItems,
    setPagingParams,
    pagination,
    orderItems,
    pagingParams,
    loadingInitial,
  } = orderStore;

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagingParams({
      pageNumber: value,
      pageSize: pagingParams.pageSize,
    });
    loadOrderItems(Number(orderId));
  };

  useEffect(() => {
    loadOrderItems(Number(orderId));
  }, [loadOrderItems, orderId]);

  if (loadingInitial) return <CircularProgress />;

  // Display order information
  const orderInfo = orderItems.length > 0 ? orderItems[0].order : null;
  const handleBack = () => {
    // Navigate back to order lookup with preserved search parameters
    navigate('/admin/warehouse/order-lookup?' + new URLSearchParams(searchParams).toString());
  };
  
  // Add status style helper function
  const getStatusStyle = (status: OrderStatus) => {
    const baseStyles = {
      fontWeight: 'medium',
      fontSize: '0.875rem',
    };

    const colorMap = {
      warning: { bg: '#fff3e0', color: '#ed6c02' },
      error: { bg: '#fde9e9', color: '#d32f2f' },
      info: { bg: '#e3f2fd', color: '#0288d1' },
      primary: { bg: '#e3f2fd', color: '#1976d2' },
      secondary: { bg: '#f3e5f5', color: '#7b1fa2' },
      success: { bg: '#e8f5e9', color: '#2e7d32' },
      default: { bg: '#f5f5f5', color: '#666666' }
    };

    const statusColor = getStatusChipColor(status);
    return {
      ...baseStyles,
      ...colorMap[statusColor]
    };
  };

  return (
    <Box sx={{ p: 3, maxWidth: '1400px', margin: '0 auto' }}>
      <OrderHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Order Details
            </Typography>
            {orderInfo && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Link 
                  href={`/admin/warehouse/order-lookup/${orderInfo.id}`}
                  color="inherit"
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant="body1">
                    Order #{orderInfo.orderNumber}
                  </Typography>
                </Link>
                <Chip 
                  label={getOrderStatusLabel(orderInfo.status as OrderStatus)}
                  color={getStatusChipColor(orderInfo.status as OrderStatus)}
                  size="small"
                  sx={{
                    fontWeight: 'medium',
                    '& .MuiChip-label': {
                      px: 2
                    }
                  }}
                />
              </Box>
            )}
          </Box>
          <Button 
            onClick={handleBack} 
            startIcon={<ArrowBackIcon />}
            variant="contained"
            color="secondary"
            sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.2)',
              }
            }}
          >
            Back to Search Results
          </Button>
        </Box>
      </OrderHeader>

      {orderInfo && (
        <StyledCard>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <ReceiptLongIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                Order Information
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[
                    { label: 'Order Number', value: orderInfo.orderNumber },
                    { label: 'Order Date', value: new Date(orderInfo.orderDate).toLocaleDateString() },
                    { label: 'Shipment Date', value: orderInfo.shipmentDate ? new Date(orderInfo.shipmentDate).toLocaleDateString() : 'Not shipped yet' },
                    { label: 'Client Name', value: orderInfo.client.name }
                  ].map(item => (
                    <Box key={item.label}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        {item.label}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                        {item.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[
                    { label: 'Sales Rep', value: orderInfo.userName },
                    { label: 'Subtotal', value: `$${orderInfo.subTotal.toFixed(2)}` }
                  ].map(item => (
                    <Box key={item.label}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        {item.label}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                        {item.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      )}

      <StyledCard>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <InventoryIcon sx={{ mr: 2, color: 'primary.main' }} />
            <Typography variant="h6">Order Items</Typography>
          </Box>

          <TableContainer sx={{ 
            borderRadius: 2,
            boxShadow: 'none',
            border: '1px solid',
            borderColor: 'divider'
          }}>
            <Table sx={{ minWidth: 650 }} aria-label="order items table">
              <TableHead sx={{ bgcolor: 'primary.main' }}>
                <TableRow>
                  {['Image', 'Product Name', 'Product Code', 'Quantity', 'Unit Price', 'Total Price', 'Case / Unit'].map((header) => (
                    <TableCell key={header}>
                      <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                        {header}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderItems && orderItems.map((orderItem) => (
                  <TableRow
                    key={orderItem.id}
                    sx={{
                      backgroundColor: Array.from(orderStore.highlightedItems).some(
                        (highlightedItem) =>
                          highlightedItem.id === orderItem.product.id &&
                          highlightedItem.byCase === orderItem.byCase
                      )
                        ? 'rgba(237, 233, 157, 0.3)'
                        : 'inherit',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                        transform: 'translateY(-1px)',
                        transition: 'all 0.2s ease'
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        component="img"
                        src={`${process.env.REACT_APP_API_ROOT}${orderItem.product.imageUrl}`}
                        alt={orderItem.product.name}
                        sx={{ 
                          width: 90,
                          height: 90,
                          objectFit: 'contain',
                          borderRadius: 1,
                          boxShadow: 1
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'medium' }}>{orderItem.product.name}</TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                        {orderItem.product.code}
                      </Typography>
                    </TableCell>
                    <TableCell>{orderItem.quantity}</TableCell>
                    <TableCell>
                      <Typography color="primary.main">
                        ${orderItem.unitPrice.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="primary.main" sx={{ fontWeight: 'medium' }}>
                        ${orderItem.totalPrice.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={orderItem.byCase ? "Case" : "Unit"}
                        color={orderItem.byCase ? "primary" : "default"}
                        size="small"
                        sx={{
                          fontWeight: 'medium',
                          '& .MuiChip-label': {
                            px: 2
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </StyledCard>

      <Box sx={{ 
        mt: 3, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="body2" color="text.secondary">
          {pagination && `Showing ${orderItems.length} of ${pagination.totalItems} items`}
        </Typography>
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
          sx={{
            '& .MuiPaginationItem-root': {
              borderRadius: 1
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default observer(OrderLookupOrderItems);
