import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import OrderManagement from './OrderHandling/OrderManagement.tsx';
import InventoryControl from './InventoryControl/InventoryControl.tsx';
import OrderLookup from './OrderLookup.tsx';
import ShipManagement from './ShipHandling/ShipManagement.tsx';
import ReturnManagement from './ReturnHandling/ReturnManagement.tsx';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`warehouse-tabpanel-${index}`}
      aria-labelledby={`warehouse-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `warehouse-tab-${index}`,
    'aria-controls': `warehouse-tabpanel-${index}`,
  };
}

const tabRoutes = [
  'order-management',
  'inventory-control',
  'order-lookup',
  'ship-management',
  'returns'
];

export default function WarehouseDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const path = location.pathname.split('/').filter(Boolean).pop() || '';
    const index = tabRoutes.indexOf(path);
    setValue(index !== -1 ? index : 0);
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/admin/warehouse/${tabRoutes[newValue]}`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="warehouse dashboard tabs">
          <Tab label="Order Management" {...a11yProps(0)} />
          <Tab label="Inventory Control" {...a11yProps(1)} />
          <Tab label="Order Lookup" {...a11yProps(2)} />
          <Tab label="Order Shipment" {...a11yProps(3)} />
          <Tab 
            label="Returns" 
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      
      <TabPanel value={value} index={0}>
        <OrderManagement />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InventoryControl />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrderLookup />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ShipManagement />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ReturnManagement />
      </TabPanel>
    </Box>
  );
}
